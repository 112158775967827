import React from 'react';
import { Form } from 'react-bootstrap';
import './ValidationError.css';

interface Props {
  errors?: {
    validation?: {
      [key: string]: string;
    }
  }
  hide: boolean;
  name: string;
}

export default function ValidationErrorComponent(props: Props) {
  const { errors, name, hide } = props;
  if (!errors || !errors.validation || !errors.validation[name] || hide) {
    return null;
  }

  const validationError = errors.validation[name];

  return (
    <Form.Text className={'validation-error-message'}>
      { validationError }
    </Form.Text>
  )
}
