import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { Container, Row, Col, Table, Spinner } from 'react-bootstrap';
import qs from 'query-string';
import { FaTrash, FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import moment from 'moment';
import { RootState } from '../../lib/store';
import SmPagination from '../../components/form/SmPagination';
import useApiAction from '../../lib/hooks/useApiAction';
import { OrderConfig } from '../../common/types';
import { deleteExternalProtocolRequest } from '../../lib/services/contact.service';
import { Link } from 'react-router-dom';

const LIMIT = 50;

function ExternalProtocolList () {
  const location = useLocation();
  const history = useHistory();
  const {page} = qs.parse(location.search);
  const externalProtocolList = useSelector((state: RootState) => state.externalProtocolListState);
  const [pageNumber, setPageNumber] = useState<number>(page ? +page : 1);
  const { listExternalProtocol } = useApiAction();
  const [order, setOrder] = useState<OrderConfig[]>([]);
  const now = moment();
  const inTwoWeeks = moment().add(2, 'weeks');

  const refresh = useCallback(() => {
    return listExternalProtocol({
        limit: LIMIT,
        offset: (pageNumber -1) * LIMIT,
        order
      });
  }, [listExternalProtocol, pageNumber, order]);

  useEffect(() => {
    refresh();
  }, [refresh]);


  async function deleteProtocol(id: number) {
    try {
      const {error} = await deleteExternalProtocolRequest({id})
      if (!error) {
        refresh();
      }
    } catch(e) { }
  }
  function toggleOrder (name: string) {
    const myValueOrder = order.find((o) => o.column === name);

    if (!myValueOrder) {
      return setOrder([{ column: name, order: 'asc' }])
    }

    if (myValueOrder.order === 'asc') {
      return setOrder([{ column: name, order: 'desc' }])
    }

    return setOrder([]);
  }

  function currentOrderIcon (name: string) {
    const myValueOrder = order.find((o) => o.column === name);
    if (myValueOrder?.order === 'asc') {
      return <FaSortUp />
    }

    if (myValueOrder?.order === 'desc') {
      return <FaSortDown />
    }

    return <FaSort />
  }

  if (!externalProtocolList.list) {
    return (<Container> <div className="page-loading"><Spinner animation="grow" /></div></Container>)
  }

  function goToPage(p: number) {
    history.push({
      pathname: '/contacts/external-protocols',
      search: `?page=${p}`
    });
    setPageNumber(p);
  }


  function getProtocolExpireStyle(pr: {expires_at: Date}) {
    if (!pr.expires_at) {
      return '';
    }

    const expiryTime = moment(pr.expires_at);
    const isExpiringSoon = expiryTime.isBetween(now, inTwoWeeks);
    const isAlreadyExpired = expiryTime.isSameOrBefore(now);

    if (isAlreadyExpired) {
      return 'text-danger';
    }

    if (isExpiringSoon) {
      return 'text-warning';
    }

    return '';
  }

  return (
    <>
      <h1>
        Välised Aegumised
      </h1>

      {externalProtocolList.loading ? (
        <Container>
          <div className="page-loading"><Spinner animation="grow" /></div>
        </Container>) : (
      <>
        <Row>
          <Col>
            <Table className={'table-sm'}>
              <thead className="thead-light">
              <tr>
                <th className={'text-muted cursor-pointer'} onClick={() => toggleOrder('external_protocol.id')}>{currentOrderIcon('external_protocol.id')}</th>
                <th>Kontakt</th>
                <th>Reg nr</th>
                <th className="d-none d-lg-table-cell cursor-pointer" onClick={() => toggleOrder('external_protocol.expires_at')}>Aegub {currentOrderIcon('external_protocol.expires_at')}</th>
                <th />
              </tr>
              </thead>
              <tbody>
              {externalProtocolList.list.map((pr) => {
                return <tr key={pr.id}>
                  <td className={'text-muted'}>{pr.id}</td>
                  <td><Link to={`/contacts/edit/${pr.contact_id}`}>{pr.contact_name}</Link></td>
                  <td>{pr.license}</td>
                  <td className={`d-none d-lg-table-cell ${getProtocolExpireStyle(pr)}`}>{ pr.expires_at ? moment.utc(pr.expires_at).format('DD/MM/YYYY') : '' }</td>
                  <td>
                      <span style={{marginRight: '0.3em', cursor: 'pointer'}} onClick={() => deleteProtocol(pr.id)}>
                        <FaTrash style={{verticalAlign: 'unset'}} className={'btn-link'} />
                      </span>
                  </td>
                </tr>
              })}
              </tbody>
            </Table>
          </Col>
        </Row>

        <SmPagination count={externalProtocolList.count} pageNumber={pageNumber} goToPage={(pnr) => goToPage(pnr)} limit={LIMIT}/>
      </>)}
    </>
  );
}

export default ExternalProtocolList;
