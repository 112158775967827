import { useDispatch } from 'react-redux';
import { sliceActions } from '../slices';
import { useMemo } from 'react';

export default function useSliceAction() {
  const dispatch = useDispatch();

  const wrapToUseDispatch = <T extends Array<any>, U>(fn: (...args: T) => any) => {
    return (...args: T): any => dispatch(fn(...args))
  };

  return useMemo(() => {
    // @ts-ignore
    const items: typeof sliceActions = Object.keys(sliceActions).reduce((actions, sliceName) => {
      // @ts-ignore
      const actionsPerSlice = sliceActions[sliceName];
      // @ts-ignore
      actions[sliceName] = Object.keys(actionsPerSlice).reduce((oneSliceActions, actionName) => {
        // @ts-ignore
        oneSliceActions[actionName] = wrapToUseDispatch(sliceActions[sliceName][actionName]);
        return oneSliceActions;
      }, {});
      return actions;
    }, {});

    return items;
  }, []);
}
