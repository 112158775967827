import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import FormElement from '../../components/form/FormElement';
import { createValidator } from '../../lib/core';
import { UserUpdatePasswordValidationSchema, UserUpdateValidationSchema } from '../../common/validators/user';
import { toast } from 'react-toastify';
import { RootState } from '../../lib/store';
import useApiAction from '../../lib/hooks/useApiAction';
import useSliceAction from '../../lib/hooks/useSliceAction';
import { updateUserRequest } from '../../lib/services/user.service';

const userUpdatePasswordValidator = createValidator(UserUpdatePasswordValidationSchema);
const userUpdateValidator = createValidator(UserUpdateValidationSchema);

function User () {
  const { updateUserPassword } = useApiAction();
  const { userUpdateActions, userActions } = useSliceAction();
  const userPasswordUpdate = useSelector((state: RootState) => state.userUpdateState);
  const user = useSelector((state: RootState) => state.userState.data);
  const [userUpdateData, setUserUpdateData] = useState({
    card_nr: user?.card_nr || ''
  });
  const [userPasswordUpdateData, setUserPasswordUpdateData] = useState({
    password: '',
    new_password: '',
    new_password_repeat: ''
  });
  const [userUpdateErrors, setUserUpdateErrors] = useState<any>();
  const [errors, setErrors] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>();
  const [isUserUpdateDisabled, setIsUserUpdateDisabled] = useState<boolean>();

  useEffect(() => {
    return () => {
      userUpdateActions.userUpdateReset();
    }
  }, []);

  useEffect(() => {
    if (userPasswordUpdate.data) {
      setIsDisabled(true);
    }
  }, [userPasswordUpdate.data])

  useEffect(() => {
    if (userPasswordUpdate.error) {
      setIsDisabled(true);
    } else {
      setErrors(false);
    }

    setErrors(userPasswordUpdate.error);
  }, [userPasswordUpdate.error]);

  useEffect(() => {
    async function validate() {
      const {valid, error} = await userUpdatePasswordValidator(userPasswordUpdateData);

      setIsDisabled(!valid);
      setErrors(error);
    }

    validate();
  }, [userPasswordUpdateData]);

  useEffect(() => {
    async function validate() {
      const {valid, error} = await userUpdateValidator(userUpdateData);

      setIsUserUpdateDisabled(!valid);
      setUserUpdateErrors(error);
    }

    validate();
  }, [userUpdateData]);

  useEffect(() => {
    if (userPasswordUpdate.data) {
      toast.info('Parool muudetud');
    }
  }, [userPasswordUpdate.data]);

  function extendFactory(key: string) {
    return (v: string) => {
      setUserPasswordUpdateData({
        ...userPasswordUpdateData,
        [key]: v
      });
    }
  }

  function handlePasswordFormSubmit(e: any) {
    e.preventDefault();

    updateUserPassword(userPasswordUpdateData);
  }

  async function handleUserUpdateFormSubmit(e: any) {
    e.preventDefault();

    try {
      // @ts-ignore
      const { error, meta } = await updateUserRequest({ data: userUpdateData });

      setIsUserUpdateDisabled(!error);
      if (error) {
        setUserUpdateErrors(error)
      } else {
        toast.info('Kasutaja muudetud');
      }

      if (meta?.setUser) {
        userActions.setUser(meta.setUser)
      }
    } catch (e) {

    }
  }

  return (
    <Container>
      <h1 id="general">Kasutaja Seaded</h1>
      {
          !user?.card_nr ? (
            <Row className={'mb-3'}>
              <Col className={'validation-error-message'}>Protokolli sisestamiseks on vajalik Töökoja kaardi number</Col>
            </Row>
          ) : null
        }
      <Form onSubmit={handleUserUpdateFormSubmit}>
        <div className="form-section">
        <FormElement errors={userUpdateErrors} value={userUpdateData.card_nr} label='Töökoja kaardi number' validationKey='card_nr' type='text' onChange={(v) => setUserUpdateData({...userUpdateData, card_nr: v})} />
        <Button type="submit" className={'btn-primary'} disabled={isUserUpdateDisabled} block >Salvesta</Button>
        </div>
      </Form>
      <hr />
      <Form onSubmit={handlePasswordFormSubmit}>
        <h2>Muuda parool</h2>
        <div className="form-section">
          <FormElement errors={errors} value={userPasswordUpdateData.password} label={'Vana Parool'} validationKey={'password'} type={'password'} onChange={extendFactory('password')}/>
          <FormElement errors={errors} value={userPasswordUpdateData.new_password} label={'Uus Parool'} validationKey={'new_password'} type={'password'} onChange={extendFactory('new_password')}/>
          <FormElement errors={errors} value={userPasswordUpdateData.new_password_repeat} label={'Uus Parool uuesti'} validationKey={'new_password_repeat'} type={'password'} onChange={extendFactory('new_password_repeat')}/>
        </div>
        <Button type="submit" className={'btn-primary'} disabled={isDisabled} block >Salvesta</Button>
      </Form>
    </Container>
  )
}

export default User;
