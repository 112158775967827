import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, InputGroup, Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import ClientFields from '../../components/client/ClientFields';
import { ProtocolType } from '../../common/types';
import FormElement from '../../components/form/FormElement';
import { RootState } from '../../lib/store';
import useApiAction from '../../lib/hooks/useApiAction';
import useSliceAction from '../../lib/hooks/useSliceAction';
import ContactSearch from '../../components/contact/ContactSearch';
import { updateProtocolRequest } from '../../lib/services/protocol.service';
import SmLoading from '../../components/form/SmLoading';
import SmDatePicker from '../../components/form/SmDatePicker';
import { toast } from 'react-toastify';
import moment from 'moment';
import { archive, unarchive } from '../../lib/services/protocol.service';

const protocolTypes = [
  {value: ProtocolType.PERIODIC, label: 'Perioodiline'},
  {value: ProtocolType.INSTALL, label: 'Paigaldus'},
  {value: ProtocolType.MAINTAIN_INSTALL, label: 'Remont ja paigaldus'},
];

const smartTypes = [
  { value: 'goods', label: 'Kaup' },
  { value: 'passenger', label: 'Inimesed' }
];

function ProtocolOne () {
  const { protocolId } = useParams<any>();
  const { getOneProtocol, selectClient } = useApiAction();
  const { selectedClientActions } = useSliceAction();
  
  const protocol = useSelector((state: RootState) => state.protocolOneState);
  const user = useSelector((state: RootState) => state.userState);
  const selectedClient = useSelector((state: RootState) => state.selectedClientState);

  const [contactData, setContactData] = useState<{
    label?: string;
    id?: number;
  }>({
    label: undefined,
    id: protocol.data?.contact_id
  });

  const [expiresAt, setExpiresAt] = useState(protocol.data?.expires_at ? new Date(protocol.data.expires_at) : new Date());

  const [updateDisabled, setUpdateDisabled] = useState(false);
  useEffect(() => {
    getOneProtocol({id: +protocolId});

    return () => {
      selectedClientActions.resetClient();
    }
  }, []);

  useEffect(() => {
    if (protocol.data?.contact_id) {
      setContactData({
        label: undefined,
        id: protocol.data?.contact_id
      });
    }
  }, [protocol.data?.contact_id])

  useEffect(() => {
    if (protocol.data?.expires_at) {
      setExpiresAt(new Date(protocol.data?.expires_at));
    }
  }, [protocol.data?.expires_at])

  useEffect(() => {
    if (protocol.data) {
      const contactNotChanged = contactData.id === protocol.data.contact_id;
      const expireNotChanged = moment(expiresAt).isSame(moment(protocol.data.expires_at));

      setUpdateDisabled(contactNotChanged && expireNotChanged);
    }
  }, [contactData.id, expiresAt, protocol.data]); 

  useEffect(() => {
    if (protocol.data?.client_id) {
      selectClient({id: protocol.data?.client_id});
    }
  }, [protocol]);

  async function archiveProtocol(protocolId: number) {
    try { 
      const {error} = await archive(protocolId);
      if (!error) {
        toast.info('Protokoll arhiveeritud');
        getOneProtocol({id: +protocolId});
      }
    } catch (e) { }
  }

  async function unarchiveProtocol(protocolId: number) {
    try { 
      const {error} = await unarchive(protocolId);
      if (!error) {
        toast.info('Protokoll taastatud');
        getOneProtocol({id: +protocolId});
      }
    } catch (e) { }
  }

  if (!protocol.data) {
    return <SmLoading />
  }

  async function saveContact() {
    try {
      if (protocol.data && expiresAt) {
        const {error} = await updateProtocolRequest({ id: protocol.data.id }, { contact_id: contactData.id, expires_at: expiresAt });

        if (!error) {
          toast.info('Protokoll salvestatud');
          getOneProtocol({id: +protocolId});
        } else {
          toast.error('Viga protokolli salvestamisel!')
        }
      }
    } catch (e) {

    }
  }
  
  return (
    <Container>
      <h1>Protokoll</h1>
   
      { user.data?.is_global_admin ? (
      <div className="form-section">
        <Row>
          <Col xs={12} sm={6}>
            <Form.Group controlId="expire">
              <Form.Label>Kontroll Aegub</Form.Label>
              <SmDatePicker onChange={(v: Date) => setExpiresAt(v)} selected={expiresAt} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="contact">
                <Form.Label>Kontakt</Form.Label>
                <ContactSearch value={contactData.id} label={contactData.label} onChange={(v) => setContactData({ id: v.value, label: v.label }) }/>
              </Form.Group>
          </Col>
        </Row>

        <Button className={'btn-primary mt-4'} block onClick={() => saveContact()} disabled={updateDisabled} >Salvesta</Button>
      </div>
      ): null }
      
      <div className="form-section">
        { !protocol.data?.archived ? (
          // @ts-ignore
          <Button className={'btn-danger mt-1'} block onClick={() => archiveProtocol(protocol.data.id)}>Kustuta Protokoll</Button>
        ) : null }
        { protocol.data?.archived ? (
          // @ts-ignore
          <Button className={'btn-primary mt-1'} block onClick={() => unarchiveProtocol(protocol.data.id)}>Taasta Protokoll</Button>
        ) : null }
      </div>

      <div className="form-section">
        <h3>1. Klient</h3>
        <ClientFields onChange={() => { }} client={selectedClient.client || {
            driver: '',
            general_data: '',
            vin: '',
            license: ''
        }} errors={undefined} disabled={true}/>
      </div>

      <div className="form-section">
        <h3>2. Üldandmed</h3>
        <Row>
          <Col xs={12} sm={12}>
            <Form.Group controlId="type">
              <Form.Label>Kontrolli tüüp</Form.Label>
              <Form.Control type="text" value={protocolTypes.find(p => protocol.data?.type === p.value)?.label} onChange={() => {}} disabled />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Form.Group controlId="odometer_before">
              <Form.Label>Odomeeter enne</Form.Label>
              <Form.Control type="number" value={protocol.data?.odometer_before} onChange={() => {}} disabled />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group controlId="odometer_after">
              <Form.Label>Odomeeter pärast</Form.Label>
              <Form.Control type="number" value={protocol.data?.odometer_after} onChange={() => {}} disabled/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Form.Group controlId="tachograph_type">
              <Form.Label>Sõidumeeriku tüüp</Form.Label>
              <Form.Control type="text" value={protocol.data?.tachograph_type} onChange={() => {}} disabled/>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group controlId="tachograph_id">
              <Form.Label>Sõidumeeriku seerianumber</Form.Label>
              <Form.Control type="text" value={protocol.data?.tachograph_id} onChange={() => {}} disabled/>
            </Form.Group>
          </Col>
        </Row>
      </div>

      <div className="form-section">
        <h3>3. Sõiduki andmed</h3>
        <Row>
          <Col xs={12} sm={6}>
            <Form.Group controlId="tire_size">
              <Form.Label>Rehvimõõt</Form.Label>
              <Form.Control type="text" value={protocol.data?.tire_size} onChange={() => {}} disabled/>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group controlId="tire_type">
              <Form.Label>Rehvi tüüp</Form.Label>
              <Form.Control type="text" value={protocol.data?.tire_type} onChange={() => {}} disabled/>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group controlId="tire_pressure">
              <Form.Label>Rehvirõhk</Form.Label>

              <InputGroup>
                <Form.Control type="number" step="0.01" value={protocol.data?.tire_pressure} onChange={() => {}} disabled/>
                <InputGroup.Append>
                  <InputGroup.Text>bar</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group controlId="tire_circumference">
              <Form.Label>Rehvi ümbermõõt "I"</Form.Label>
              <InputGroup>
                <Form.Control type="number" value={protocol.data?.tire_circumference} onChange={() => {}} disabled/>
                <InputGroup.Append>
                  <InputGroup.Text>mm</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group controlId="vehicle_coefficient">
              <Form.Label>Sõiduki tunnustegur "W"</Form.Label>
              <InputGroup>
                <Form.Control type="number" value={protocol.data?.vehicle_coefficient} onChange={() => {}} disabled/>
                <InputGroup.Append>
                  <InputGroup.Text>imp/km</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </div>

      <div className="form-section">
        <h3>4. Sõidumeeriku kontroll</h3>
        <Row>
          <Col xs={12} sm={6}>
            <FormElement errors={undefined} value={protocol.data?.smart} label={'Arukas sõidumeerik'} validationKey={'smart'} type={'switch'} onChange={() => {}} disabled={true}/>
          </Col>
          <Col xs={12} sm={6}>
            <FormElement errors={undefined} value={protocol.data?.manipulation_device_found} label={'Manipuleerimisseade tuvastatud'} validationKey={'manipulation_device_found'} type={'switch'} onChange={() => {}} disabled={true}/>
          </Col>
        </Row>
        {
          protocol.data?.smart ? (
            <>
              <Row>
                <Col xs={12} sm={6}>
                  <FormElement errors={undefined} value={protocol.data?.smart_gnss_verified} label={'GNSS kontrollitud'} validationKey={'smart_gnss_verified'} type={'switch'} onChange={() => {}} disabled={true}/>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={6}>
                  <FormElement errors={undefined} value={protocol.data?.smart_dsrc_sn} label={'DSRC Seerianumber'} validationKey={'smart_dsrc_sn'} type={'text'} onChange={() => {}} disabled={true}/>
                </Col>
                <Col xs={12} sm={6}>
                  <FormElement errors={undefined} value={protocol.data?.smart_seal_nr} label={'SMART plommi seerianumber'} validationKey={'smart_seal_nr'} type={'text'} onChange={() => {}} disabled={true}/>
                </Col>
                <Col xs={12} sm={6}>
                  <FormElement errors={undefined} value={protocol.data?.smart_seal_location} label={'SMART plommi asukoht'} validationKey={'smart_seal_location'} type={'text'} onChange={() => {}} disabled={true}/>
                </Col>
                <Col xs={12} sm={6}>
                  <FormElement errors={undefined} value={smartTypes.find(t => t.value === protocol.data?.smart_type)?.label || 'puudub'} label={'Veose liik'} validationKey={'smart_type'} type={'text'} onChange={() => {}} disabled={true}/>
                </Col>
              </Row>
            </>
          ) : null
        }
        <Row>
          <Col xs={12} sm={6}>
            <Form.Group controlId="tachograph_constant">
              <Form.Label>Sõidumeeriku konstant "K"</Form.Label>

              <InputGroup>
                <Form.Control type="number" value={protocol.data?.tachograph_constant} onChange={() => {}} disabled/>
                <InputGroup.Append>
                  <InputGroup.Text>imp/km</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group controlId="speed_deviation">
              <Form.Label>Kiiruse hälve</Form.Label>
              <InputGroup>
                <Form.Control type="number" value={protocol.data?.speed_deviation} onChange={() => {}} disabled/>
                <InputGroup.Append>
                  <InputGroup.Text>km/h</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group controlId="distance_deviation">
              <Form.Label>Vahemaa hälve</Form.Label>
              <InputGroup>
                <Form.Control type="number" value={protocol.data?.distance_deviation} onChange={() => {}} disabled/>
                <InputGroup.Append>
                  <InputGroup.Text>m/1000m</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group controlId="time_deviation">
              <Form.Label>Aja hälve</Form.Label>
              <InputGroup>
                <Form.Control type="number" value={protocol.data?.time_deviation} onChange={() => {}} step="0.1" disabled/>
                <InputGroup.Append>
                  <InputGroup.Text>s/24h</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </div>

      <div className="form-section">
        <h3>5. Kiiruspiiraja kontroll</h3>
        <Row>
          <Col xs={12} sm={6}>
            <Form.Group controlId="speed_limiter">
              <Form.Check
                type="switch"
                checked={protocol.data?.speed_limiter}
                label="Kiiruspiiraja"
                onChange={() => {}}
                disabled
              />
            </Form.Group>
          </Col>

          { protocol.data?.speed_limiter ? (
            <Col xs={12} sm={6}>
              <Form.Group controlId="speed_limiter_top_speed">
                <Form.Label>Max kiirus</Form.Label>
                <InputGroup>
                  <Form.Control type="number" value={protocol.data?.speed_limiter_top_speed} onChange={() => {}} disabled/>
                  <InputGroup.Append>
                    <InputGroup.Text>km/h</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Col>
          ) : null }
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Form.Group controlId="diagram_card_archived">
              <Form.Check
                type="switch"
                label="Diagrammketas joonistatud ning arhiveeritud"
                checked={protocol.data?.diagram_card_archived}
                onChange={() => {}}
                disabled
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group controlId="tachograph_archived">
              <Form.Check
                type="switch"
                checked={protocol.data?.tachograph_archived}
                label="Digimeeriku andmed arhiveeritud"
                onChange={() => {}}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
      </div>

    </Container>
  );
}

export default ProtocolOne;
