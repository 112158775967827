import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { acceptInvitationAction, loginAction, recoverPasswordAction } from '../services/user.service';
import { Dispatch } from 'react';
import { UserSelectData, RouteAcceptInvitationInput, RouteLoginInput, RouteLoginOutput, RouteRecoverPasswordInput } from '../../common/types';

export function setUserAndToken(data: { token: any, user: any }) {
  window.localStorage.setItem('token', data.token);
  window.localStorage.setItem('user', JSON.stringify(data.user));
}

export function getUser () {
  try {
    const lsVal = window.localStorage.getItem('user');
    return lsVal ? JSON.parse(lsVal) : undefined;
  } catch (e) {
    return undefined;
  }
}

export function getToken () {
  return window.localStorage.getItem('token') || undefined;
}

export function isLoggedIn () {
  const user = getUser();
  const token =  getToken();
  return !!(token && user && user.id);
}

export interface UserState {
  data?: UserSelectData;
  token?: string;
  loginError: any;
  isLoggedIn: boolean;
  loginLoading: boolean;
}

let initialState: UserState = {
  data: getUser(),
  token: getToken(),
  loginError: null,
  isLoggedIn: isLoggedIn(),
  loginLoading: false
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginApiPending (state) {
      state.loginLoading = true;
    },
    loginApiFulfilled (state, action: PayloadAction<RouteLoginOutput>) {
      state.loginLoading = false;
      state.loginError = undefined;
      state.isLoggedIn = true;
      state.data = action.payload.user;
      state.token = action.payload.token;
      setUserAndToken(action.payload);
    },
    loginApiRejected (state, action: PayloadAction<ApiError>) {
      state.loginLoading = false;
      state.loginError = action.payload;
      state.isLoggedIn = false;
      state.data = undefined;
      state.token = undefined;
      setUserAndToken({ token: undefined, user: undefined });
    },
    setUser (state, action: PayloadAction<{user: UserSelectData, token: string;}>) {
      state.loginLoading = false;
      state.loginError = undefined;
      state.isLoggedIn = !!action.payload.token;
      state.data = action.payload.user;
      state.token = action.payload.token;
      setUserAndToken({ user: action.payload.user, token: action.payload.token });
    },
    logout (state) {
      state.data = undefined;
      state.token = undefined;
      state.isLoggedIn = false;
      setUserAndToken({ token: undefined, user: undefined });
    }
  },
});

export function login(data: RouteLoginInput) {
  return (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.loginApiPending());
    const action = loginAction({ data },
      {
        onSuccess: (r) => {
          dispatch(slice.actions.loginApiFulfilled(r))
        },
        onFailure: (e) => dispatch(slice.actions.loginApiRejected(e))
      });

    dispatch(action);
  }
}

export function acceptInvitation(data: RouteAcceptInvitationInput) {
  return (dispatch: Dispatch<any>) => {
    // dispatch(slice.actions.loginApiPending());
    const action = acceptInvitationAction({ data },
      {
        onSuccess: (r) => dispatch(slice.actions.loginApiFulfilled(r)),
        onFailure: (e) => dispatch(slice.actions.loginApiRejected(e))
      });


    dispatch(action);
  }
}
export function recoverPassword(data: RouteRecoverPasswordInput) {
  return (dispatch: Dispatch<any>) => {
    // dispatch(slice.actions.loginApiPending());
    const action = recoverPasswordAction({ data },
      {
        onSuccess: (r) => dispatch(slice.actions.loginApiFulfilled(r)),
        onFailure: (e) => dispatch(slice.actions.loginApiRejected(e))
      });

    dispatch(action);
  }
}

export default slice;
