import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { ContactTableFields, RouteContactOneOutput } from '../../common/types';
import { Dispatch } from 'react';
import { getOneContactAction } from '../services/contact.service';

export interface ContactOneState {
  data?: ContactTableFields;
  loading: boolean;
  error: any;
}

let initialState: ContactOneState = {
  data: undefined,
  loading: false,
  error: null
};


const slice = createSlice({
  name: 'contactOne',
  initialState,
  reducers: {
    contactOnePending (state) {
      state.loading = true;
    },
    contactOneFulfilled (state, action: PayloadAction<RouteContactOneOutput>) {
      state.loading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    contactOneRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
  },
});

export function getOneContact(query: { id: number; }) {
  return (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.contactOnePending());
    const action = getOneContactAction(
      query,
      {
        onSuccess: (r) => dispatch(slice.actions.contactOneFulfilled(r)),
        onFailure: (e) => dispatch(slice.actions.contactOneRejected(e))
      });
    dispatch(action);
  }
}


export default slice;
