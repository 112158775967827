import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { Dispatch } from 'react';
import { ExternalProtocolTableData, ListQuery, RouteContactExternalProtocolListOutput } from '../../common/types';
import { listExternalProtocolAction } from '../services/contact.service';

export interface ExternalProtocolListState {
  list: ExternalProtocolTableData[],
  count: number;
  loading: boolean;
  error: any;
}

let initialState: ExternalProtocolListState = {
  list: [],
  count: 0,
  loading: false,
  error: null
};

const slice = createSlice({
  name: 'externalProtocolList',
  initialState,
  reducers: {
    externalProtocolListPending (state) {
      state.loading = true;
    },
    externalProtocolListFulfilled(state, action: PayloadAction<RouteContactExternalProtocolListOutput>) {
      state.loading = false;
      state.count = action.payload.count;
      state.list = action.payload.items;
      state.error = undefined;
    },
    externalProtocolListRejected(state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.list = [];
      state.count = 0;
    }
  },
});

export function listExternalProtocol(query: Partial<ListQuery>) {
  return (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.externalProtocolListPending);
    const action = listExternalProtocolAction({ query }, {
      onSuccess: (r) => dispatch(slice.actions.externalProtocolListFulfilled(r)),
      onFailure: (e) => dispatch(slice.actions.externalProtocolListRejected(e))
    });
    dispatch(action);
  }
}

export default slice;