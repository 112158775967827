import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import SmLoading from '../../components/form/SmLoading';
import useApiAction from '../../lib/hooks/useApiAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../lib/store';
import { useParams } from 'react-router-dom';
import { FaSync } from 'react-icons/fa';
import { reinviteCompanyUser } from '../../lib/services/company.service';

function CompanyUser () {
  const { getCompanyUser } = useApiAction();
  const { companyId, userId } = useParams<any>();

  const userData = useSelector((state: RootState) => state.companyOneUserState);

  useEffect(() => {
    getCompanyUser({ companyId, userId });
  }, [getCompanyUser]);

  if (userData.loading) {
    return <SmLoading />;
  }

  if (!userData.data) {
    return (
      <Row>
        <Col>Kasutajat ei leitud</Col>
      </Row>
    )
  }

  async function reinvite() {
    try {
      await reinviteCompanyUser({ companyId, userId });
      getCompanyUser({ companyId, userId });
    } catch (e) {}
  }

  return (
    <>
      <h1>Kasutaja { userData.data.first_name }</h1>
      <Row className={'mb-1'}>
        <Col>
            Email:
        </Col>
        <Col> {userData.data.email} </Col>
      </Row>
      <Row className={'mb-1'}>
        <Col>
            Admin:
        </Col>
        <Col> {userData.data.is_admin ? 'Jah' : 'Ei'} </Col>
      </Row>

      { userData.data.invitation_hash ? (
        <Row className={'mb-1'}>
          <Col>
            Kutse link:
          </Col>
          <Col>
            <span style={{marginLeft: '0.3em', cursor: 'pointer'}} onClick={() => reinvite()}>
              <FaSync style={{float: 'left'}}/>
            </span>
            <span>
              {userData.data.invitation_hash}
            </span>
          </Col>
        </Row>
      ) : null }

      { userData.data.forgot_password_hash ? (
        <Row className={'mb-1'}>
          <Col>
            Parooli taastamise link:
          </Col>
          <Col> {userData.data.forgot_password_hash} </Col>
        </Row>
      ) : null}
    </>
  );
}

export default CompanyUser;
