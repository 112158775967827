import { useDispatch } from 'react-redux';
import { listProtocol } from '../slices/protocolListSlice';
import { getCompany} from '../slices/companyOneSlice';
import { updateCompany} from '../slices/companyUpdateSlice';
import { updateCompanyProtocolCounter } from '../slices/companyProtocolCounterUpdateSlice';
import { createProtocol} from '../slices/protocolCreateSlice';
import { selectClient} from '../slices/selectedClientSlice';
import { acceptInvitation, login, recoverPassword } from '../slices/userSlice';
import { updateUserPassword } from '../slices/userUpdateSlice';
import { useMemo } from 'react';
import { listCompanyUsers } from '../slices/companyUserListSlice';
import { listCompanies } from '../slices/companyListSlice';
import { getCompanyUser } from '../slices/companyOneUserSlice';
import { listContact } from '../slices/contactListSlice';
import { getOneContact } from '../slices/contactOneSlice';
import { getOneProtocol } from '../slices/protocolOneSlice';
import { listExternalProtocol } from '../slices/externalProtocolListSlice';

export default function useApiAction() {
  const dispatch = useDispatch();

  const wrapToUseDispatch = <T extends Array<any>, U>(fn: (...args: T) => any) => {
    return (...args: T): any => dispatch(fn(...args))
  };

  return useMemo(() => ({
    listProtocol: wrapToUseDispatch(listProtocol),
    listExternalProtocol: wrapToUseDispatch(listExternalProtocol),
    getCompany: wrapToUseDispatch(getCompany),
    updateCompany: wrapToUseDispatch(updateCompany),
    updateCompanyProtocolCounter: wrapToUseDispatch(updateCompanyProtocolCounter),
    createProtocol: wrapToUseDispatch(createProtocol),
    selectClient: wrapToUseDispatch(selectClient),
    login: wrapToUseDispatch(login),
    updateUserPassword: wrapToUseDispatch(updateUserPassword),
    listCompanyUsers: wrapToUseDispatch(listCompanyUsers),
    listCompanies: wrapToUseDispatch(listCompanies),
    getCompanyUser: wrapToUseDispatch(getCompanyUser),
    acceptInvitation: wrapToUseDispatch(acceptInvitation),
    recoverPassword: wrapToUseDispatch(recoverPassword),
    listContact: wrapToUseDispatch(listContact),
    getOneContact: wrapToUseDispatch(getOneContact),
    getOneProtocol: wrapToUseDispatch(getOneProtocol),
  }), []);
}
