import React, { useEffect, useState } from 'react';
import './Wrapper.scss';
import SideBar from './Sidebar';
import Content from './Content';

function Wrapper({children}: {children?: any}) {
  const [isSideBarToggled, setSideBarToggle] = useState(false);

  return (
    <div className={'app'}>
      <SideBar isSideBarToggled={isSideBarToggled} setSideBarToggle={setSideBarToggle} />
      <Content children={children} setSideBarToggle={setSideBarToggle} isSideBarToggled={isSideBarToggled}/>
    </div>
  );
}

export default Wrapper