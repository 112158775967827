import { ClientSelectData, RouteListClientOutput } from '../../common/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';

export interface ClientListState {
  list: ClientSelectData[];
  count: number;
  loading: boolean;
  error: any;
}

let initialState: ClientListState = {
  list: [],
  count: 0,
  loading: false,
  error: null
};

const slice = createSlice({
  name: 'clientList',
  initialState,
  reducers: {
    clientListPending (state) {
      state.loading = true;
    },
    clientListFulfilled(state, action: PayloadAction<RouteListClientOutput>) {
      state.loading = false;
      state.count = action.payload.count;
      state.list = action.payload.items;
      state.error = undefined;
    },
    clientListRejected(state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.list = [];
      state.count = 0;
    }
  },
});

export default slice;