import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaEnvelope, FaTrash, FaTrashRestore, FaSync } from 'react-icons/fa';
import { CompanyUserData } from '../../../common/types';
import useApiAction from '../../../lib/hooks/useApiAction';
import { archiveCompanyUser, unarchiveCompanyUser, updateCompanyUser, reinviteCompanyUser } from '../../../lib/services/company.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../lib/store';
import { toast } from 'react-toastify';

function CompanyUserListRow (props: {
  user: CompanyUserData
}) {
  const { listCompanyUsers } = useApiAction();
  const currentUser = useSelector((state: RootState) => state.userState.data);
  const [reinviteSent, setReinviteSent] = useState(false);
  async function setUserArchived(userId: number) {
    try {
      await archiveCompanyUser({
        companyId: props.user.company_id,
        id: userId
      });
      listCompanyUsers(props.user.company_id);
    } catch (e) {}
  }

  async function setUserUnarchived(userId: number) {
    try {
      await unarchiveCompanyUser({
        companyId: props.user.company_id,
        id: userId
      });
      listCompanyUsers(props.user.company_id);
    } catch (e) {}
  }

  async function toggleUserAdmin(userId: number, isAdmin: boolean) {
    try {
      await updateCompanyUser({ companyId: props.user.company_id, id: userId }, { is_admin: isAdmin });
      listCompanyUsers(props.user.company_id);
    } catch (e) {}
  }

  async function reinvite(userId: number, email: string) {
    try {
      await reinviteCompanyUser({ companyId: props.user.company_id, userId: userId });
      toast.info(`Uus kutse saadetud: ${email}`);
      setReinviteSent(true);
    } catch (e) {}
  }

  return (<tr className={props.user.archived ? 'text-muted': ''}>
    <td>{props.user.first_name} {props.user.last_name}</td>
    <td>
      {/* <Link style={{marginLeft: '0.3em'}} to={`/company/${props.user.company_id}/user-list/edit/${props.user.id}`} > */}
        {props.user.email}
      {/* </Link> */}
    </td>
    <td>
      <Form.Group controlId={`user_${props.user.id}_switch`}>
        <Form.Check
          type="switch"
          checked={props.user.is_admin}
          disabled={currentUser?.id === props.user.id}
          label={''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            toggleUserAdmin(props.user.id, e.target.checked);
          }}
        />
      </Form.Group>
    </td>
    {
      currentUser?.id === props.user.id ? (
        <td></td>
      ) : (
      <td style={{ textAlign: 'right' }}>

          { props.user.invitation_hash ? (
            <span>
              <FaEnvelope style={{verticalAlign: 'unset'}} />
            </span>
          ) : null }

          { props.user.invitation_hash && !reinviteSent ? (
            <span style={{marginLeft: '0.3em', cursor: 'pointer'}} onClick={() => reinvite(props.user.id, props.user.email) }>
              <FaSync style={{verticalAlign: 'unset'}} className={'btn-link'} />
            </span>
          ) : null }

          {
            !props.user.archived ? (
              <span style={{marginLeft: '0.3em', cursor: 'pointer'}} onClick={() => setUserArchived(props.user.id)}>
                <FaTrash style={{verticalAlign: 'unset'}} className={'btn-link'} />
              </span>
            ) : (
              <span style={{marginLeft: '0.3em', cursor: 'pointer'}} onClick={() => setUserUnarchived(props.user.id)}>
                <FaTrashRestore style={{verticalAlign: 'unset'}} className={'btn-link'} />
              </span>
            )
          }
        </td>
      )
    }

  </tr>)
          
}

export default CompanyUserListRow;
