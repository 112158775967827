import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { CompanySelectData } from '../../common/types';
import { Dispatch } from 'react';
import { getFullCompanyAction } from '../services/user.service';

export interface CompanyGetState {
  data?: CompanySelectData;
  loading: boolean;
  error: any;
}

let initialState: CompanyGetState = {
  data: undefined,
  loading: false,
  error: null
};


const slice = createSlice({
  name: 'companyOne',
  initialState,
  reducers: {
    companyOnePending (state) {
      state.loading = true;
    },
    companyOneFulfilled (state, action: PayloadAction<CompanySelectData>) {
      state.loading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    companyOneRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
  },
});

export function getCompany() {
  return (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.companyOnePending());
    const action = getFullCompanyAction(
      {
        onSuccess: (r) => dispatch(slice.actions.companyOneFulfilled(r)),
        onFailure: (e) => dispatch(slice.actions.companyOneRejected(e))
      });
    dispatch(action);
  }
}


export default slice;
