import React, { useEffect } from 'react';
import useApiAction from '../../lib/hooks/useApiAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../lib/store';
import SmLoading from '../../components/form/SmLoading';
import { Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CompanyListRow from './components/CompanyListRow';

function CompanyList () {
  const { listCompanies } = useApiAction();
  const companyList = useSelector((state: RootState) => state.companyListState);

  useEffect(() => {
    listCompanies();
  }, []);

  if (companyList.loading) {
    return <SmLoading />;
  }

  return (
    <>
      <h1>Ettevõtted</h1>
      <Row className="mb-3">
        <Col className="col-sm-12"><Link to="/companies/invite" className="btn btn-primary float-right">Lisa ettevõte</Link></Col>
      </Row>
      <Row>
        <Col>
          <Table className={'table-sm'}>
            <thead className="thead-light">
            <tr>
              <th>Nimi</th>
              <th>Aktiivseid kasutajaid</th>
              <th />
            </tr>
            </thead>
            <tbody>
            { companyList.list.map((c) => <CompanyListRow company={c}/>) }
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}

export default CompanyList;
