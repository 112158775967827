import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { UserSelectWithHashes } from '../../common/types';
import { Dispatch } from 'react';
import { getCompanyUserAction } from '../services/company.service';

export interface CompanyGetUserState {
  data?: UserSelectWithHashes;
  loading: boolean;
  error: any;
}

let initialState: CompanyGetUserState = {
  data: undefined,
  loading: false,
  error: null
};


const slice = createSlice({
  name: 'companyOneUser',
  initialState,
  reducers: {
    companyOneUserPending (state) {
      state.loading = true;
    },
    companyOneUserFulfilled (state, action: PayloadAction<UserSelectWithHashes>) {
      state.loading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    companyOneUserRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
  },
});

export function getCompanyUser(query: { companyId: number; userId: number; }) {
  return (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.companyOneUserPending());
    const action = getCompanyUserAction(
      query,
      {
        onSuccess: (r) => dispatch(slice.actions.companyOneUserFulfilled(r)),
        onFailure: (e) => dispatch(slice.actions.companyOneUserRejected(e))
      });
    dispatch(action);
  }
}


export default slice;
