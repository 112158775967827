import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { RouteUpdateUserCompanyProtocolCounterInput, UserSelectData } from '../../common/types';
import { Dispatch } from 'react';
import { updateCompanyProtocolCounterAction } from '../services/user.service';
import authSlice from './userSlice';

export interface CompanyProtocolCounterUpdateState {
  data?: {user: UserSelectData; token: string};
  loading: boolean;
  error: any;
}

let initialState: CompanyProtocolCounterUpdateState = {
  data: undefined,
  loading: false,
  error: null
};

const slice = createSlice({
  name: 'companyProtocolCounterUpdate',
  initialState,
  reducers: {
    companyProtocolCounterUpdatePending (state) {
      state.loading = true;
    },
    companyProtocolCounterUpdateFulfilled (state, action: PayloadAction<{user: UserSelectData; token: string}>) {
      state.loading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    companyProtocolCounterUpdateRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
    reset (state) {
      state.data = undefined;
      state.loading = false;
      state.error = undefined;
    }
  },
});

export function updateCompanyProtocolCounter(data: RouteUpdateUserCompanyProtocolCounterInput) {
  return (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.companyProtocolCounterUpdatePending());
    const action = updateCompanyProtocolCounterAction({ data },
      {
        onSuccess: (r) => {
          dispatch(authSlice.actions.setUser(r));
          dispatch(slice.actions.companyProtocolCounterUpdateFulfilled(r));
        },
        onFailure: (e) => dispatch(slice.actions.companyProtocolCounterUpdateRejected(e))
      })
    dispatch(action);
  }
}

export default slice;
