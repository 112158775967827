import React, { useState } from 'react';
import { Container, Col, Row, Table, Button } from 'react-bootstrap';
import CSVReader from 'react-csv-reader';
import { useHistory } from 'react-router-dom';
import { importContactsRequest } from '../../../lib/services/contact.service';
import './ContactImport.scss';
import ContactImportRow from './ContactImportRow';

const papaparseOptions = {
  header: false,
  dynamicTyping: false,
  skipEmptyLines: true
};

function ContactImport () {

    const [importList, setImportList] = useState<string[][] | undefined>();
    const history = useHistory();
    const excludeSet = new Set();
    const header = ['Klient', 'Telefon', 'email', 'reg nr', 'aegub'];

    async function saveList() {
        const filteredList = importList?.filter((_, i) => !excludeSet.has(i)) || [];
        try {
            const {error} = await importContactsRequest(filteredList);

            if (!error) {
                history.push('/contacts');
            } else { }
        } catch (e) {

        }
    }
    return (<>
        {importList && importList.length ? (
            <Container>
                <h3 className={'text-center'}>Nimekiri importimiseks</h3>

                <Table striped bordered hover size='sm'>
                    <thead>
                        <tr>
                            <td></td>
                            <td>{header[0]}</td>
                            <td>{header[1]}</td>
                            <td>{header[2]}</td>
                            <td>{header[3]}</td>
                            <td>{header[4]}</td>
                        </tr>
                    </thead>
                    <tbody>
                        {importList.map((row, i) => (<ContactImportRow row={row} key={i} index={i} onUseRow={(use: boolean) => {
                            if (use) {
                                excludeSet.delete(i);
                            } else {
                                excludeSet.add(i);
                            }
                        }} />))}
                    </tbody>
                </Table>
                <Row className='mt-3 justify-content-end'>
                    <Button className="btn btn-primary mr-3" onClick={() => setImportList([])}>Tühista</Button>
                    <Button className="btn btn-primary mr-3" onClick={() => saveList()}>Salvesta</Button>
                </Row>
            </Container>
        ): (
            <Container>
                <Row>
                    <Col className={'col-sm-12'}>
                        <CSVReader
                            cssClass="react-csv-input"
                            label="Impordi kontakti nimekiri CSV failist"
                            onFileLoaded={(data) => setImportList(data)}
                            parserOptions={papaparseOptions}
                        />
                    </Col>
                </Row>
            </Container>
        )}
    </>);
}

export default ContactImport;