import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Form } from 'react-bootstrap';
import FormElement from '../../components/form/FormElement';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from '../../lib/store';
import { Redirect, useParams } from 'react-router';
import { createValidator } from '../../lib/core';
import { UserSetupSchema } from '../../common/validators/user';
import useApiAction from '../../lib/hooks/useApiAction';

const userSetupValidator = createValidator(UserSetupSchema);

function AcceptInvitation () {
  const userState = useSelector((state: RootState) => state.userState);
  const { hash } = useParams();
  const { acceptInvitation } = useApiAction();
  const [userSetupData, setUserSetupData] = useState({
    first_name: '',
    last_name: '',
    password: '',
    password_repeat: ''
  });
  const [userSetupErrors, setUserSetupErrors] = useState<any>();
  const [isUserSetupDisabled, setIsUserSetupDisabled] = useState<boolean>();

  useEffect(() => {
    async function validate() {
      const {valid, error} = await userSetupValidator({
        ...userSetupData,
        hash
      });

      setIsUserSetupDisabled(!valid);
      setUserSetupErrors(error);
    }

    validate();
  }, [userSetupData]);

  function extendFactory(key: string) {
    return (v: string) => {
      setUserSetupData({
        ...userSetupData,
        [key]: v
      });
    }
  }

  function handleFormSubmit(e: any) {
    e.preventDefault();

    acceptInvitation({
      ...userSetupData,
      hash
    });
  }

  if (userState.data) {
    return <Redirect to={'/'} />
  }

  return (
    <Container>
      <h1>Kasutaja loomine</h1>

      <Form onSubmit={handleFormSubmit}>
        <div className="form-section">
          <FormElement errors={userSetupErrors} value={userSetupData.first_name} label={'Eesnimi'} validationKey={'first_name'} type={'text'} onChange={extendFactory('first_name')}/>
          <FormElement errors={userSetupErrors} value={userSetupData.last_name} label={'Perekonnanimi'} validationKey={'last_name'} type={'text'} onChange={extendFactory('last_name')}/>
          <FormElement errors={userSetupErrors} value={userSetupData.password} label={'Uus parool'} validationKey={'password'} type={'password'} onChange={extendFactory('password')}/>
          <FormElement errors={userSetupErrors} value={userSetupData.password_repeat} label={'Uus parool'} validationKey={'password_repeat'} type={'password'} onChange={extendFactory('password_repeat')}/>

          <Form.Group>
            <Form.Text className={'validation-error-message'}>
              { userState.loginError?.message || null }
            </Form.Text>
          </Form.Group>
        </div>
        <Button type="submit" className={'btn-primary'} disabled={isUserSetupDisabled} block >Edasi</Button>
      </Form>
    </Container>
  )
}

export default AcceptInvitation;
