import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import FormElement from '../../components/form/FormElement';
import { createValidator } from '../../lib/core';
import { updateContactRequest, sendContactSmsRequest } from '../../lib/services/contact.service';

import { ContactSendSmsValidationSchema, ContactUpdateValidationSchema } from '../../common/validators/contact';
import { toast } from 'react-toastify';
import useApiAction from '../../lib/hooks/useApiAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../lib/store';
import SmLoading from '../../components/form/SmLoading';

const contactEditValidator = createValidator(ContactUpdateValidationSchema);
const contactSmsValidator = createValidator(ContactSendSmsValidationSchema);

function EditContact () {
  const { contactId } = useParams<any>();
  const { getOneContact } = useApiAction();
  const contact = useSelector((state: RootState) => state.contactOneState);
  const [errors, setErrors] = useState<any>();
  const [smsErrors, setSmsErrors] = useState<any>();
  const [smsSuccess, setSmsSuccess] = useState<any>(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [smsText, setSmsText] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getOneContact({ id: +contactId });
  }, []);

  useEffect(() => {
    if (contact.data) {
      setEmail(contact.data?.email || '');
      setPhone(contact.data?.phone || '');
      setCompanyName(contact.data?.name || '');
    }
  }, [contact]);
  useEffect(() => {
    async function validate() {
      const {error} = await contactEditValidator({ email, name: companyName, phone });

      setErrors(error);
    }

    validate();
  }, [email, companyName, phone]);

  useEffect(() => {
    async function validate() {
      const {error} = await contactSmsValidator({ text: smsText });

      setSmsErrors(error);
    }

    validate();
  }, [smsText]);

  async function handleFormSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    try {
      const {error} = await updateContactRequest({id: contactId }, {email, name: companyName, phone});
      setErrors(error);
      setIsLoading(false);

      if (!error) {
        toast.info('Kontakt edukalt muudetud');
      }
    } catch (e) {
      setIsLoading(false);
    }
  }

  async function handleSmsFormSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    try {
      const {error} = await sendContactSmsRequest({id: contactId }, {text: smsText});
      setIsLoading(false);
      
      if (!error) {
        setSmsSuccess(true);
        setSmsText('');
        toast.info('SMS edukalt saadetud');
      } else {
        setSmsErrors(error);
      }

    } catch (e) {
      setIsLoading(false);
    }
  }

  if (contact.loading) {
    return <SmLoading />
  }

  return (
    <>
      <h1>Muuda Kontakti</h1>

      <Form onSubmit={handleFormSubmit}>
        <div className="form-section">
          <FormElement errors={errors} value={companyName} label={'Ettevõtte nimi'} validationKey={'company_name'} type={'text'} onChange={(v) => { setCompanyName(v);}}/>
          <FormElement errors={errors} value={email} label={'Email'} validationKey={'email'} type={'email'} onChange={(v) => { setEmail(v);}}/>
          <FormElement errors={errors} value={phone} label={'Telefon'} validationKey={'phone'} type={'text'} onChange={(v) => { setPhone(v);}}/>
        </div>
        <Button type="submit" className={'btn-primary'} disabled={isLoading || !!errors} block >Muuda</Button>
      </Form>

      <h3>Saada SMS</h3>
      <Form onSubmit={handleSmsFormSubmit}>
        <div className="form-section">
          <FormElement errors={smsErrors} value={smsText} label={''} validationKey={'text'} type={'text'} onChange={(v) => { setSmsText(v);}}/>
        </div>
        <Button type="submit" className={'btn-primary'} disabled={isLoading || !!smsErrors || smsSuccess } block >Saada SMS</Button>
      </Form>
    </>
  );
}

export default EditContact;
