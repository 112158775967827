import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import ValidationErrorComponent from '../../components/app/ValidationError';

interface textProps {
  errors: any;
  value: any;
  label: string;
  validationKey: string;
  type: 'text' | 'password' | 'number' | 'email'
  disabled?: boolean;
  onChange: (v: string) => void
}

interface switchProps {
  errors: any;
  value: any;
  label: string;
  validationKey: string;
  type: 'switch';
  disabled?: boolean;
  onChange: (v: boolean) => void;
}

type Props = textProps | switchProps;

function FormElement (props: Props) {
  const [valueTouched, setValueTouched] = useState(false);

  function extendTextData(e: React.ChangeEvent<HTMLInputElement>) {
    setValueTouched(true);
    //@ts-ignore
    props.onChange(e.target.value);
  }

  function extendBoolData (e: React.ChangeEvent<HTMLInputElement>) {
    setValueTouched(true);
    //@ts-ignore
    props.onChange(e.target.checked);
  }
  if (props.type === 'switch') {
    return (
      <Form.Group controlId={props.validationKey}>
        <Form.Check
          type="switch"
          checked={props.value}
          label={props.label}
          onChange={extendBoolData}
          disabled={props.disabled}
        />
        <ValidationErrorComponent hide={!valueTouched} name={props.validationKey} errors={props.errors} />
      </Form.Group>
    )
  }

  return (
    <Form.Group controlId={props.validationKey}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control type={props.type} value={props.value} onChange={extendTextData} disabled={props.disabled}/>
      <ValidationErrorComponent hide={!valueTouched} name={props.validationKey} errors={props.errors} />
    </Form.Group>
  )
};

export default FormElement;
