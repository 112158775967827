enum ResponseCodes {
  INVALID_REQUEST = 1,
  DATABASE_ERROR = 2,
  VALIDATION_ERROR = 3,

  OK = 200,

  NOT_AUTHENTICATED = 401,
  NOT_AUTHORIZED = 403,
  NOT_FOUND = 404,
  TOKEN_EXPIRED = 440,
  INTERNAL_SERVER_ERROR = 500
}

export default ResponseCodes;
