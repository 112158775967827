import React, { useState } from 'react';
import { FaTrash, FaTrashRestore, FaKey } from 'react-icons/fa';
import { CompanyListSelectData } from '../../../common/types';
import useApiAction from '../../../lib/hooks/useApiAction';
import { archiveCompany, unarchiveCompany } from '../../../lib/services/company.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../lib/store';
import { Link } from 'react-router-dom';

function CompanyListRow (props: {
  company: CompanyListSelectData
}) {
  const { listCompanies } = useApiAction();
  const user = useSelector((state: RootState) => state.userState.data);
  async function setCompanyArchived(companyId: number) {
    try {
      await archiveCompany(companyId);
      listCompanies();
    } catch (e) {}
  }

  async function setCompanyUnarchived(companyId: number) {
    try {
      await unarchiveCompany(companyId);
      listCompanies();
    } catch (e) {}
  }

  return (<tr className={props.company.archived ? 'text-muted': ''}>
    <td><Link to={`/company/${props.company.id}/user-list/`}>{props.company.name}</Link></td>
    <td>{props.company.active_users}</td>

    {
      user?.company_id === props.company.id ? (
        <td></td>
      ) : (
        <td>
          {
            !props.company.archived ? (
              <span style={{marginLeft: '0.3em', cursor: 'pointer'}} onClick={() => setCompanyArchived(props.company.id)}>
                <FaTrash style={{verticalAlign: 'unset'}} className={'btn-link'} />
              </span>
            ) : (
              <span style={{marginLeft: '0.3em', cursor: 'pointer'}} onClick={() => setCompanyUnarchived(props.company.id)}>
                <FaTrashRestore style={{verticalAlign: 'unset'}} className={'btn-link'} />
              </span>
            )
          }
          {/*<span style={{marginLeft: '0.3em', cursor: 'pointer'}} onClick={() => triggerInvite(props.company.id)}>*/}
          {/*  <FaKey style={{verticalAlign: 'unset'}} className={'btn-link'} />*/}
          {/*</span>*/}
        </td>
      )
    }
  </tr>)
}

export default CompanyListRow;
