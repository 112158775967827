import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { RouteUpdateUserPasswordInput, UserSelectData } from '../../common/types';
import { Dispatch } from 'react';
import { updateUserPasswordAction } from '../services/user.service';
import authSlice from './userSlice';

export interface UserUpdateState {
  data?: {user: UserSelectData; token: string};
  loading: boolean;
  error: any;
}

let initialState: UserUpdateState = {
  data: undefined,
  loading: false,
  error: undefined
};

const slice = createSlice({
  name: 'userUpdate',
  initialState,
  reducers: {
    userUpdatePending (state) {
      state.loading = true;
    },
    userUpdateFulfilled (state, action: PayloadAction<{user: UserSelectData; token: string;}>) {
      state.loading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    userUpdateRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
    userUpdateReset (state) {
      state.data = undefined;
      state.loading = false;
      state.error = undefined;
    }
  },
});

export function updateUserPassword(data: RouteUpdateUserPasswordInput) {
  return (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.userUpdatePending());
    const action = updateUserPasswordAction({ data },
      {
        onSuccess: (r) => {
          dispatch(authSlice.actions.setUser(r));
          dispatch(slice.actions.userUpdateFulfilled(r));
        },
        onFailure: (e) => dispatch(slice.actions.userUpdateRejected(e))
      })
    dispatch(action);
  }
}

export default slice;
