import {
  OneQuery,
  RouteCreateClientInput,
  RouteCreateClientOutput,
  RouteListClientInput,
  RouteListClientOutput,
  RouteOneClientOutput
} from '../../common/types';
import { apiAction, ApiActionOptions, apiRequest, buildListQuery, Methods } from '../middleware/api';

export async function create(createBody: RouteCreateClientInput) {
  return apiRequest<RouteCreateClientOutput>({ path: '/client', method: Methods.POST, body: createBody });
}

export async function getOne(id: number){
  return apiRequest<RouteOneClientOutput>({ path: `/client/${id}`, method: Methods.GET });
}

export async function listRequest(query: Partial<RouteListClientInput>) {
  let path = '/client?';
  return apiRequest<RouteListClientOutput>({
    method: Methods.GET,
    path: buildListQuery({ path, query }),
  })
}

export function getOneClientAction(
  { query }: { query: OneQuery },
  { onSuccess, onFailure }: ApiActionOptions<RouteOneClientOutput>
) {
  const path = `/client/${query.id}`;
  return apiAction<RouteOneClientOutput>({
    method: Methods.GET,
    path,
    onSuccess,
    onFailure
  })
}
// export async function listAction(
//   { query }: {query: Partial<RouteListClientInput>},
//   { onSuccess, onFailure }: ApiActionOptions<ListResponse<RouteListClientOutput>>
// ) {
//   let path = '/client?';
//   return apiAction<ListResponse<RouteListClientOutput>>({
//     method: Methods.GET,
//     path: buildListQuery({ path, query }),
//     onSuccess,
//     onFailure,
//   })
// }