import React from 'react';
import { Provider } from 'react-redux';
import store from '../../lib/store';
import RouterWrapper from './RouterWrapper';

function App() {
  return (
    <Provider store={store}>
      <RouterWrapper/>
    </Provider>
  );
}

export default App;
