import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { RouteCreateProtocolInput, RouteCreateProtocolOutput } from '../../common/types';
import { Dispatch } from 'react';
import { createAction } from '../services/protocol.service';

export interface ProtocolCreateState {
  data?: RouteCreateProtocolOutput,
  loading: boolean;
  error: any;
}

let initialState: ProtocolCreateState = {
  data: undefined,
  loading: false,
  error: null
};

const slice = createSlice({
  name: 'protocolCreate',
  initialState,
  reducers: {
    protocolCreatePending (state) {
      state.loading = true;
    },
    protocolCreateFulfilled (state, action: PayloadAction<RouteCreateProtocolOutput>) {
      state.loading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    protocolCreateRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
    reset(state) {
      state.loading = false;
      state.data = undefined;
      state.error = null;
    }
  },
});

export function createProtocol(data: RouteCreateProtocolInput) {
  return (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.protocolCreatePending());
    const action = createAction({ data }, {
      onSuccess: (resp) => dispatch(slice.actions.protocolCreateFulfilled(resp)),
      onFailure: (e) => dispatch(slice.actions.protocolCreateRejected(e))
    });
    dispatch(action);
  }
}

export default slice;
