import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { apiUrl } from '../../lib/config';
import { Container, Row, Col, Table, Spinner, Tab, Tabs, Form } from 'react-bootstrap';
import qs from 'query-string';
import { FaFileDownload, FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import moment from 'moment';
import { archive, unarchive } from '../../lib/services/protocol.service';
import useDebounce from '../../lib/hooks/useDebounce';
import { RootState } from '../../lib/store';
import SmPagination from '../../components/form/SmPagination';
import useApiAction from '../../lib/hooks/useApiAction';
import { Link } from 'react-router-dom';
import { OrderConfig, ProtocolSelectWithClientData } from '../../common/types';
import Select from 'react-select';

const LIMIT = 50;

function ProtocolList () {
  const location = useLocation();
  const history = useHistory();
  const {page} = qs.parse(location.search);
  const user = useSelector((state: RootState) => state.userState);
  const companyUsers = useSelector((state: RootState) => state.companyUserListState);
  const protocolList = useSelector((state: RootState) => state.protocolListState);
  const [filterAuthor, setFilterAuthor] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(page ? +page : 1);
  const [filterByArchived, setFilterByArchived] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { listProtocol, listCompanyUsers } = useApiAction();
  const [order, setOrder] = useState<OrderConfig[]>([]);
  const now = moment();
  const inTwoWeeks = moment().add(2, 'weeks');

  const getCompanyUsersIfAdmin = useCallback(() => {
    if (user.data?.is_admin) {
      listCompanyUsers(user.data.company_id);
    }
  }, [user]);

  const refresh = useCallback(() => {
    const filter = {
      archived: filterByArchived
    };

    if (filterAuthor) {
      // @ts-ignore
      filter.user_id = filterAuthor;
    }
    return listProtocol({
        limit: LIMIT,
        offset: (pageNumber -1) * LIMIT,
        filter: JSON.stringify(filter),
        search: debouncedSearchTerm,
        order
      });
  }, [listProtocol, pageNumber, filterByArchived, debouncedSearchTerm, order, filterAuthor]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    getCompanyUsersIfAdmin();
  }, [getCompanyUsersIfAdmin]);

  useEffect(() => {
    setSearchTerm('');
  }, [filterByArchived]);

  function toggleOrder (name: string) {
    const myValueOrder = order.find((o) => o.column === name);

    if (!myValueOrder) {
      return setOrder([{ column: name, order: 'asc' }])
    }

    if (myValueOrder.order === 'asc') {
      return setOrder([{ column: name, order: 'desc' }])
    }

    return setOrder([]);
  }

  function currentOrderIcon (name: string) {
    const myValueOrder = order.find((o) => o.column === name);
    if (myValueOrder?.order === 'asc') {
      return <FaSortUp />
    }

    if (myValueOrder?.order === 'desc') {
      return <FaSortDown />
    }

    return <FaSort />
  }

  if (!protocolList.list) {
    return (<Container> <div className="page-loading"><Spinner animation="grow" /></div></Container>)
  }

  function goToPage(p: number) {
    history.push({
      pathname: '/',
      search: `?page=${p}`
    });
    setPageNumber(p);
  }

  function getProtocolExpireStyle(pr: ProtocolSelectWithClientData) {
    if (!pr.expires_at) {
      return '';
    }

    const expiryTime = moment(pr.expires_at);
    const isExpiringSoon = expiryTime.isBetween(now, inTwoWeeks);
    const isAlreadyExpired = expiryTime.isSameOrBefore(now);

    if (isAlreadyExpired) {
      return 'text-danger';
    }

    if (isExpiringSoon) {
      return 'text-warning';
    }

    return '';
  }

  return (
    <>
      <h1>
        {filterByArchived ? 'Arhiiv' : 'Protokollid'}
      </h1>
      <Tabs
        id="controlled-tab"
        activeKey={filterByArchived ? 'archive' : 'protocols'}
        onSelect={(k: string) =>
          setFilterByArchived(k === 'archive')
        }
      >
        <Tab eventKey="protocols" title="Protokollid" />
        <Tab eventKey="archive" title="Arhiiv" />
      </Tabs>
      <Form.Group controlId="search" className={'mt-3'}>
        <Form.Control
          type="text"
          value={searchTerm}
          placeholder="Otsi protokolli"
          onChange={(e) => setSearchTerm(e.target.value)}
          onBlur={() => refresh()}
        />
      </Form.Group>
      {
        user.data?.is_admin && companyUsers.list.length ? (
          <Form.Group controlId="user_filter" className={'mt-3'}>
            <Form.Label>Koostaja:</Form.Label>
            <Select
                  options={[{value: null, label: 'Kõik'}, ...companyUsers.list.map(u => ({value: u.id, label: `${u.first_name} ${u.last_name}`}))] }
                  onChange={(v) => {
                    console.log('v muutus', v);
                    //@ts-ignore
                    setFilterAuthor(v?.value);
                  }}
                  defaultValue={{value: null, label: 'Kõik'}}
                />
          </Form.Group>
        ) : null
      }
      {protocolList.loading ? (
        <Container>
          <div className="page-loading"><Spinner animation="grow" /></div>
        </Container>) : (
      <>
        <Row>
          <Col>
            <Table className={'table-sm'}>
              <thead className="thead-light">
              <tr>
                <th className={'text-muted cursor-pointer'} onClick={() => toggleOrder('protocol.id')}>{currentOrderIcon('protocol.id')}</th>
                <th>Klient</th>
                <th>Reg nr</th>
                <th className="d-none d-lg-table-cell">Mark ja mudel</th>
                <th className="d-none d-lg-table-cell cursor-pointer" onClick={() => toggleOrder('protocol.created_at')}>Lisatud {currentOrderIcon('protocol.created_at')}</th>
                
                { user.data?.is_global_admin ? (
                  <th className="d-none d-lg-table-cell cursor-pointer" onClick={() => toggleOrder('protocol.expires_at')}>Aegub {currentOrderIcon('protocol.expires_at')}</th>
                ) : null }
                <th />
              </tr>
              </thead>
              <tbody>
              {protocolList.list.map((pr) => {
                return <tr key={pr.id}>
                  <td className={'text-muted'}>
                    <Link to={`/protocol/${pr.id}`}>{pr.generated_id}</Link>
                  </td>
                  <td>{pr.client_driver}</td>
                  <td>{pr.client_license}</td>
                  <td className="d-none d-lg-table-cell">{pr.client_general_data}</td>
                  <td className="d-none d-lg-table-cell">{moment.utc(pr.created_at).format('DD/MM/YYYY')}</td>
                  { user.data?.is_global_admin ? (
                    <td className={`d-none d-lg-table-cell ${getProtocolExpireStyle(pr)}`}>{ pr.expires_at ? moment.utc(pr.expires_at).format('DD/MM/YYYY') : '' }</td>
                  ): null}
                  <td>
                    <a style={{textDecoration: 'none'}} target="_blank" rel="noopener noreferrer" href={`${apiUrl}/protocol/${pr.id}/pdf?jwt=${user.token}`} className={'color-primary'}>
                      <FaFileDownload style={{verticalAlign: 'unset'}}/>
                    </a>
                  </td>
                </tr>
              })}
              </tbody>
            </Table>
          </Col>
        </Row>

        <SmPagination count={protocolList.count} pageNumber={pageNumber} goToPage={(pnr) => goToPage(pnr)} limit={LIMIT}/>
      </>)}
    </>
  );
}

export default ProtocolList;
