import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Navbar, Spinner } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { RootState } from '../../lib/store';
import { FaBars } from 'react-icons/fa';

const headerTitles = [
  { match: '/', name: 'Protokollid' },
  { match: '/user', name: 'Kasutaja' },
  { match: '/contacts', name: 'Kontaktid' },
  { match: '/contacts/external-protocols', name: 'Süsteemivälised kontrolli aegumised' },
  { match: /\/contacts\/edit\/\d+/, name: 'Kontakt', regex: true },
  { match: '/contacts/add', name: 'Lisa Kontakt' },
  { match: '/contacts/import', name: 'Impordi kontaktid' },
  { match: '/companies', name: 'Ettevõtted' },
  { match: '/companies/invite', name: 'Lisa Ettevõte' },
  { match: /\/company\/\d+\/user-list\/invite/, name: 'Lisa Ettevõtte Kasutaja', regex: true},
  { match: /\/company\/\d+\/user-list\/edit\/\d+/, name: 'Ettevõtte Kasutaja', regex: true },
  { match: /\/company\/\d+\/user-list/, name: 'Ettevõtte Kasutajad', regex: true},
  { match: '/company', name: 'Ettevõte Seaded' },
  { match: '/protocol', name: 'Lisa Protokoll' },
  { match: /\/protocol\/\d+/, name: 'Protokoll', regex: true },
];

function Content({children, setSideBarToggle, isSideBarToggled}: {children?: any, setSideBarToggle: (v: boolean) => void, isSideBarToggled: boolean}) {
  const user = useSelector((state: RootState) => state.userState);
  const location = useLocation();

  if (user.loginLoading) {
    return (<Container>
      <div className="page-loading"><Spinner animation="grow" /></div>
    </Container>)
  }

  if (!user.isLoggedIn) {
    return (<>{children}</>)
  }

  if (!user.data?.company_seal_nr && location.pathname !== '/company') {
    return <Redirect to={'/company#general'} />
  }

  if (!user.data?.card_nr && location.pathname === '/protocol') {
    return <Redirect to={'/user#general'} />
  }

  return (
    <div className='page-wrap'>
      <Navbar className={'bg-primary navbar-dark header-title-nav'}>
        <div className={'header-title'}>
            {headerTitles.find(info => info.regex ? location.pathname.match(info.match) : info.match === location.pathname)?.name} 
        </div>
        <FaBars className={'hamburger-menu d-md-none'} onClick={() => {setSideBarToggle(!isSideBarToggled)}}/>
      </Navbar>

      <Container id={'page-content'} className={'page-content'}>
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          style={{ width: "100%" }}
          className={'text-center'}
        />
        {children}
      </Container>
    </div>
  )
}

export default Content;