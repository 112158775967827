import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import './Login.css';
import { useSelector } from 'react-redux';
import Wrapper from '../../components/wrapper/Wrapper';
import { useHistory, useLocation} from 'react-router';
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import { RootState } from '../../lib/store';
import useApiAction from '../../lib/hooks/useApiAction';
import { Link } from 'react-router-dom';


function Login () {
  const { login } = useApiAction();
  const history = useHistory();
  const location = useLocation<{
    redirectPath?: string
  }>();
  const user = useSelector((state: RootState) => state.userState);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    if (user.isLoggedIn) {
      const path = location.state?.redirectPath || '/';
      history.replace(path);
    }
  }, [user.isLoggedIn]);

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    login({email, password});
  }

  if (user.loginLoading) {
    return (
      <Wrapper>
        <div className="page-loading"><Spinner animation="grow" /></div>
      </Wrapper>
    )
  }

  return (
    <Container className=''>
      <h1 className='text-center'>Logi sisse</h1>
      <Row className='d-flex mt-5'>
        <Col sm={9} lg={5} md={7} className="mx-auto">
          <Form className="form-signin" onSubmit={onSubmit}>
            <Form.Group className="form-label-group">
              <Form.Control type="email" id="inputEmail" className="form-control" placeholder="Email" onChange={(e: React.ChangeEvent<HTMLInputElement>) => (setEmail(e.target.value))} required autoFocus />
              <Form.Label htmlFor={"inputEmail"}>Email</Form.Label>
            </Form.Group>

            <Form.Group className="form-label-group">
              <Form.Control type="password" id="inputPassword" className="form-control" onChange={(e: React.ChangeEvent<HTMLInputElement>) => (setPassword(e.target.value))} placeholder="Parool" required />
              <Form.Label htmlFor={"inputPassword"}>Parool</Form.Label>
              <Form.Text className={'validation-error-message text-center'}>
                { user.loginError ? user.loginError.message : null }
              </Form.Text>
            </Form.Group>

            <Button className="btn btn-lg btn-primary btn-block" type="submit">Logi sisse</Button>
          </Form>
        </Col>
      </Row>
      <Row className={'mt-2'}>
        <Col sm={9} lg={5} md={7} className={'text-right mx-auto small'}>
          <Link to={'/forgot-password'}>Unustasid parooli?</Link>
        </Col>
      </Row>
    </Container>
  )
}

export default Login;
