import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import FormElement from '../../components/form/FormElement';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  CompanyUpdateProtocolCounterValidationSchema,
  CompanyUpdateValidationSchema
} from '../../common/validators/company';
import { createValidator } from '../../lib/core';
import { RootState } from '../../lib/store';
import useApiAction from '../../lib/hooks/useApiAction';
import useSliceAction from '../../lib/hooks/useSliceAction';
import { CompanyUpdateProtocolCounterData } from '../../common/types';
const companyUpdateValidator = createValidator(CompanyUpdateValidationSchema);
const companyUpdateProtocolCounterValidator = createValidator(CompanyUpdateProtocolCounterValidationSchema);

function Company () {
  const user = useSelector((state: RootState) => state.userState.data);
  // const userUpdate = useSelector((state: RootState) => state.userUpdateState);
  const company = useSelector((state: RootState) => state.companyOneState);
  const companyUpdate = useSelector((state: RootState) => state.companyUpdateState);
  const companyProtocolCounterUpdate = useSelector((state: RootState) => state.companyProtocolCounterUpdateState);
  const { getCompany, updateCompanyProtocolCounter, updateCompany } = useApiAction();
  const { companyUpdateActions, companyProtocolCounterUpdateActions } = useSliceAction();

  const [companyUpdateData, setCompanyUpdateData] = useState({
    name: company.data?.name || '',
    country: company.data?.country || '',
    county: company.data?.county || '',
    city: company.data?.city || '',
    address: company.data?.address || '',
    phone: company.data?.phone || '',
    seal_nr: company.data?.seal_nr || '',
    eak: company.data?.eak || '',
  });
  const [companyUpdateErrors, setCompanyUpdateErrors] = useState<any>();
  const [isCompanyUpdateDisabled, setIsCompanyUpdateDisabled] = useState<boolean>();

  const [companyUpdateProtocolCounterData, setCompanyUpdateProtocolCounterData] = useState<{ protocol_counter: number | undefined}>({
    protocol_counter: company.data?.protocol_counter,
  });
  const [companyUpdateProtocolCounterErrors, setCompanyUpdateProtocolCounterErrors] = useState<any>();
  const [isCompanyUpdateProtocolCounterDisabled, setIsCompanyUpdateProtocolCounterDisabled] = useState<boolean>();

  useEffect(() => {
    getCompany();
    return () => {
      companyUpdateActions.reset();
      companyProtocolCounterUpdateActions.reset();
      getCompany();
    }
  }, []);

  useEffect(() => {
    if (company.data) {
      setCompanyUpdateData({
        name: company.data.name,
        country: company.data.country,
        county: company.data.county,
        city: company.data.city,
        address: company.data.address,
        phone: company.data.phone,
        seal_nr: company.data.seal_nr,
        eak: company.data.eak
      });

      setCompanyUpdateProtocolCounterData({
        protocol_counter: company.data.protocol_counter
      });
    }
  }, [company.data])

  useEffect(() => {
    async function validate() {
      const {valid, error} = await companyUpdateValidator(companyUpdateData);

      setIsCompanyUpdateDisabled(!valid);
      setCompanyUpdateErrors(error);
    }

    validate();
  }, [companyUpdateData]);

  useEffect(() => {
    async function validate() {
      const {valid, error} = await companyUpdateProtocolCounterValidator(companyUpdateProtocolCounterData);

      setIsCompanyUpdateProtocolCounterDisabled(!valid);
      setCompanyUpdateProtocolCounterErrors(error);
    }

    validate();
  }, [companyUpdateProtocolCounterData]);

  useEffect(() => {
    if (companyUpdate.data) {
      toast.info('Üldandmed Salvestatud');
    }
  }, [companyUpdate.data]);

  useEffect(() => {
    if (companyProtocolCounterUpdate.data) {
      toast.info('Protokolli loendur Salvestatud');
    }
  }, [companyProtocolCounterUpdate.data]);

  function extendFactory(key: string) {
    return (v: string) => {
      setCompanyUpdateData({
        ...companyUpdateData,
        [key]: v
      });
    }
  }

  function handleFormSubmitForCompany(e: any) {
    e.preventDefault();

    updateCompany(companyUpdateData);
  }

  function handleFormSubmitForCompanyProtocolCounter(e: any) {
    e.preventDefault();

    updateCompanyProtocolCounter(companyUpdateProtocolCounterData as CompanyUpdateProtocolCounterData);
  }

  return (
    <Container>
      <h1>Ettevõtte Seaded</h1>

      <Form onSubmit={handleFormSubmitForCompanyProtocolCounter}>
        <h2>Protokolli loendur</h2>
        <div className="form-section">
          <FormElement errors={companyUpdateProtocolCounterErrors} value={companyUpdateProtocolCounterData.protocol_counter} label={''} validationKey={'protocol_counter'} type={'number'} onChange={(v: string) => {
            setCompanyUpdateProtocolCounterData({
              protocol_counter: +v || undefined
            });
          }}/>
        </div>
        <Button type="submit" className={'btn-primary'} disabled={isCompanyUpdateProtocolCounterDisabled} block >Salvesta</Button>
      </Form>

      <Form onSubmit={handleFormSubmitForCompany}>
        <h2 id="general">Üldandmed</h2>

        {
          !user?.company_seal_nr ? (
            <Row className={'mb-3'}>
              <Col className={'validation-error-message'}>Palun sisesta ettevõtte üldandmed</Col>
            </Row>
          ) : null
        }
        <div className="form-section">
          <FormElement errors={companyUpdateErrors} value={companyUpdateData.seal_nr} label={'Plomminumber'} validationKey={'seal_nr'} type={'text'} onChange={extendFactory('seal_nr')}/>
          <FormElement errors={companyUpdateErrors} value={companyUpdateData.eak} label={'EAK number'} validationKey={'eak'} type={'text'} onChange={extendFactory('eak')}/>
          <FormElement errors={companyUpdateErrors} value={companyUpdateData.name} label={'Nimi'} validationKey={'name'} type={'text'} onChange={extendFactory('name')}/>
          <FormElement errors={companyUpdateErrors} value={companyUpdateData.country} label={'Riik'} validationKey={'country'} type={'text'} onChange={extendFactory('country')}/>
          <FormElement errors={companyUpdateErrors} value={companyUpdateData.county} label={'Maakond'} validationKey={'county'} type={'text'} onChange={extendFactory('county')}/>
          <FormElement errors={companyUpdateErrors} value={companyUpdateData.city} label={'Linn'} validationKey={'city'} type={'text'} onChange={extendFactory('city')}/>
          <FormElement errors={companyUpdateErrors} value={companyUpdateData.address} label={'Aadress'} validationKey={'address'} type={'text'} onChange={extendFactory('address')}/>
          <FormElement errors={companyUpdateErrors} value={companyUpdateData.phone} label={'Telefon'} validationKey={'phone'} type={'text'} onChange={extendFactory('phone')}/>
        </div>
        <Button type="submit" className={'btn-primary'} disabled={isCompanyUpdateDisabled} block >Salvesta</Button>
      </Form>
    </Container>
  )
}

export default Company;
