import {
  RouteCompanyInviteInput,
  RouteCompanyList,
  RouteCompanyUserInviteInput, RouteGetCompanyUserOutput,
  RouteUserCompanyList
} from '../../common/types';
import { apiAction, ApiActionOptions, apiRequest, Methods } from '../middleware/api';

export function archiveCompanyUser(data: {id: number, companyId: number}) {
  return apiRequest<any>({
    path: `/user/company/${data.companyId}/user-list/${data.id}/archive`,
    method: Methods.POST,
    body: {}
  });
}

export function unarchiveCompanyUser(data: {id: number, companyId: number}) {
  return apiRequest<any>({
    path: `/user/company/${data.companyId}/user-list/${data.id}/unarchive`,
    method: Methods.POST,
    body: {}
  });
}

export function archiveCompany(id: number) {
  return apiRequest<any>({
    path: `/user/company-list/${id}/archive`,
    method: Methods.POST,
    body: {}
  });
}

export function unarchiveCompany(id: number) {
  return apiRequest<any>({
    path: `/user/company-list/${id}/unarchive`,
    method: Methods.POST,
    body: {}
  });
}

export function inviteCompanyUser(companyId: number, body: RouteCompanyUserInviteInput) {
  return apiRequest<any>({
    path: `/user/company/${companyId}/user-list/invite`,
    method: Methods.POST,
    body
  });
}

export function inviteCompany(body: RouteCompanyInviteInput) {
  return apiRequest<any>({
    path: `/user/company-list/invite`,
    method: Methods.POST,
    body
  });
}

export function reinviteCompanyUser({
  companyId, userId
}: { companyId: number; userId: number; }) {
  return apiRequest<any>({
    path: `/user/company/${companyId}/user-list/${userId}/reinvite`,
    method: Methods.POST,
    body: {}
  });
}

export function updateCompanyUser(query: {companyId: number, id: number}, data: {is_admin: boolean}) {
  return apiRequest<any>({
    path: `/user/company/${query.companyId}/user-list/${query.id}`,
    method: Methods.PUT,
    body: data
  });
}

export function listCompanyUserAction(
  { companyId }: { companyId: number },
  { onSuccess, onFailure }: ApiActionOptions<RouteUserCompanyList>
) {
  let path = `/user/company/${companyId}/user-list`;

  return apiAction<RouteUserCompanyList>({
    method: Methods.GET,
    path: path,
    onSuccess,
    onFailure,
  });
}

export function getCompanyUserAction(
  { companyId, userId }: { companyId: number; userId: number; },
  { onSuccess, onFailure }: ApiActionOptions<RouteGetCompanyUserOutput>
) {
  let path = `/user/company/${companyId}/user-list/${userId}`;

  return apiAction<RouteGetCompanyUserOutput>({
    method: Methods.GET,
    path: path,
    onSuccess,
    onFailure,
  });
}

export function listCompaniesAction(
  { onSuccess, onFailure }: ApiActionOptions<RouteCompanyList>
) {
  let path = '/user/company-list';

  return apiAction<RouteCompanyList>({
    method: Methods.GET,
    path: path,
    onSuccess,
    onFailure,
  });
}