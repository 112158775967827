import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { RouteCreateClientOutput } from '../../common/types';

export interface ClientCreateState {
  data?: RouteCreateClientOutput;
  loading: boolean;
  error:any;
}

let initialState: ClientCreateState = {
  data: undefined,
  loading: false,
  error: null
};

const slice = createSlice({
  name: 'clientCreate',
  initialState,
  reducers: {
    clientCreatePending (state) {
      state.loading = true;
    },
    clientCreateFulfilled (state, action: PayloadAction<RouteCreateClientOutput>) {
      state.loading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    clientCreateRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
  },
});

export default slice;
