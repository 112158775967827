import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientSelectData } from '../../common/types';
import { Dispatch } from 'react';
import { getOneClientAction } from '../services/client.service';

export interface SelectedClientState {
  client?: ClientSelectData,
}

let initialState: SelectedClientState = {
  client: undefined
};

const slice = createSlice({
  name: 'selectedClient',
  initialState,
  reducers: {
    setSelectedClient (state, action: PayloadAction<ClientSelectData>) {
      state.client = action.payload;
    },
    resetClient (state) {
      state.client = undefined;
    }
  },
});

export function selectClient(query: { id: number; }) {
  return (dispatch: Dispatch<any>) => {
    const action = getOneClientAction({ query }, {
      onSuccess: (r) => {
        dispatch(slice.actions.setSelectedClient(r))
      },
      onFailure: (e) => console.log(e)
    });
    return dispatch(action);
  }
}

export default slice;
