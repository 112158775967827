import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { PhoneNumberUtil } from 'google-libphonenumber';
import moment from 'moment';
import 'moment/locale/et';
moment.locale('et');

const phoneUtil = new PhoneNumberUtil();

function ContactImportRow (props: {row: string[], index: number; onUseRow: (use: boolean) => void}) {
    const [companyName, phone, email, license, expires_at] = props.row;


    let phoneNr;
    try {
        phoneNr = phoneUtil.parse(phone, 'EE');
    } catch (e) {}

    let isPhoneValid = false;
    if (phoneNr) {
        isPhoneValid = phoneUtil.isValidNumber(phoneNr);
    }

    const parsedMoment = moment(expires_at, 'DD.MM.YYYY');
    let isExpireValid = parsedMoment.isValid();

    const [useRow, setUseRow] = useState(isPhoneValid && isExpireValid);
    useEffect(() => {
        props.onUseRow(useRow);
    }, [useRow]);

    return (<tr>
        <td>
            <Form.Group controlId={`import-row-${props.index}`}>
                <Form.Check
                    checked={useRow}
                    label={''}
                    onChange={(e: any) => {
                        setUseRow(e.target.checked);
                    }}
                    disabled={!isPhoneValid || !isExpireValid}

                />
            </Form.Group>

        </td>
        <td>{companyName}</td>
        <td className={isPhoneValid ? '': 'validation-error-message'}>{isPhoneValid && phoneNr? `+${phoneNr.getCountryCode()}${phoneNr.getNationalNumber()}` : phone}</td>
        <td>{email}</td>
        <td>{license}</td>
        <td className={isExpireValid ? '': 'validation-error-message'}>{isExpireValid ? parsedMoment.format('D MMM YYYY') : expires_at}</td>
    </tr>)
}

export default ContactImportRow;