import rootReducer from './slices/index';
import {
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import apiMiddleware from './middleware/api';

export type RootState = ReturnType<typeof rootReducer>;

export default configureStore({
  reducer: rootReducer,
  middleware: [
    //@ts-ignore
    ...getDefaultMiddleware<RootState>({
      //@ts-ignore
      serializableCheck: {
        //@ts-ignore
        ignoredActions: [ 'API' ],
      },
    }),
    apiMiddleware
  ],
});
