import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import ProtocolList from '../../pages/Protocol/ProtocolList';
import Login from '../../pages/Login/Login';
import User from '../../pages/User/User';
import Protocol from '../../pages/Protocol/Protocol';
import PrivateRoute from '../wrapper/PrivateRoute';
import Wrapper from '../wrapper/Wrapper';
import AddCompanyUser from '../../pages/User/AddCompanyUser';
import Company from '../../pages/User/Company';
import CompanyList from '../../pages/User/CompanyList';
import CompanyUserList from '../../pages/User/CompanyUserList';
import AddCompany from '../../pages/User/AddCompany';
import CompanyUser from '../../pages/User/CompanyUser';
import AcceptInvitation from '../../pages/User/AcceptInvitation';
import ForgotPassword from '../../pages/Login/ForgotPassword';
import RecoverPassword from '../../pages/User/RecoverPassword';
import ContactList from '../../pages/Contact/ContactList';
import AddContact from '../../pages/Contact/AddContact';
import EditContact from '../../pages/Contact/EditContact';
import ProtocolOne from '../../pages/Protocol/ProtocolOne';
import ContactImport from '../../pages/Contact/components/ContactImport';
import ExternalProtocolList from '../../pages/Contact/ExternalProtocolList';

function RouterWrapper() {

  return (
    <Router>
      <Switch>
        <Wrapper>
          <PrivateRoute path="/" component={ProtocolList} exact={true} />
          <PrivateRoute path="/user" component={User} exact={true} />
          <PrivateRoute path="/contacts" component={ContactList} exact={true} />
          <PrivateRoute path="/contacts/external-protocols" component={ExternalProtocolList} exact={true} />
          <PrivateRoute path="/contacts/add" component={AddContact} exact={true} />
          <PrivateRoute path="/contacts/import" component={ContactImport} exact={true} />
          <PrivateRoute path="/contacts/edit/:contactId" component={EditContact} exact={true} />
          <PrivateRoute path="/companies" component={CompanyList} exact={true} />
          <PrivateRoute path="/companies/invite" component={AddCompany} exact={true} />
          <PrivateRoute path="/company/:companyId/user-list" component={CompanyUserList} exact={true} />
          <PrivateRoute path="/company/:companyId/user-list/invite" component={AddCompanyUser} exact={true} />
          <PrivateRoute path="/company/:companyId/user-list/edit/:userId" component={CompanyUser} exact={true} />
          <PrivateRoute path="/company" component={Company} exact={true} />
          <PrivateRoute path="/protocol" component={Protocol} exact={true} />
          <PrivateRoute path="/protocol/:protocolId" component={ProtocolOne} exact={true} />

          <Route path="/login" component={Login} exact={true} />
          <Route path="/forgot-password" component={ForgotPassword} exact={true} />
          <Route path="/accept-invitation/:hash" component={AcceptInvitation} exact={true} />
          <Route path="/recover-password/:hash" component={RecoverPassword} exact={true} />

        </Wrapper>
      </Switch>
    </Router>
  );
}

export default RouterWrapper;
