import React, { useEffect, useState } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';
import { FaSignOutAlt, FaListOl, FaUserCog, FaBuilding, FaPlus, FaCog, FaChevronLeft, FaChevronRight, FaPhone } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../lib/store';
import { Link, useLocation } from 'react-router-dom';
import useSliceAction from '../../lib/hooks/useSliceAction';

const defaultSideBarCollapsed = window.localStorage.getItem('defaultSideBarCollapsed');

function SideBar(props: {isSideBarToggled: boolean, setSideBarToggle: (v: boolean) => void}) {
  const user = useSelector((state: RootState) => state.userState);
  const { userActions } = useSliceAction();
  const [ collapsed, setCollapsed ] = useState(defaultSideBarCollapsed === 'on' || false);
  const location = useLocation();
  const collapsedWrap = (v: boolean) => {
    window.localStorage.setItem('defaultSideBarCollapsed', v ? 'on' : 'off');
    setCollapsed(v);
  }

  useEffect(() => {
      props.setSideBarToggle(false);
  }, [location.pathname]);

  if (!user.isLoggedIn) {
      return null;
  }

  return (
    <ProSidebar
      onToggle={(t) => props.setSideBarToggle(t)}
      toggled={props.isSideBarToggled}
      collapsed={collapsed}
      breakPoint="md"
    >
      <SidebarHeader>
        <Menu iconShape="square">
          {
            collapsed ?
              (<span onClick={()=> collapsedWrap(!collapsed)}><MenuItem icon={<FaChevronRight className={'sm-sidebar-icon'} />}></MenuItem></span>):
              (<span onClick={()=> collapsedWrap(!collapsed)}><MenuItem icon={<FaChevronLeft className={'sm-sidebar-icon'} />}></MenuItem></span>)
            }
          
        </Menu>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="square">
          <MenuItem icon={<FaListOl />} > <Link to="/">Protokollid</Link> </MenuItem>
          <MenuItem icon={<FaPlus />} > <Link to="/protocol">Koosta Protokoll</Link> </MenuItem>
          <MenuItem icon={<FaUserCog />} > <Link to="/user">Minu seaded</Link></MenuItem>

          <SubMenu title={user.data?.company_name} icon={<FaCog />}>
            <MenuItem> <Link to="/company">Ettevõtte Seaded</Link></MenuItem>
            { user.data?.is_admin ? (<MenuItem> <Link to={`/company/${user.data?.company_id}/user-list`}>Ettevõtte Kasutajad</Link> </MenuItem>): (<></>) }
            { user.data?.is_admin ? (<MenuItem> <Link to={`/company/${user.data?.company_id}/user-list/invite`}>Kutsu Kasutaja</Link> </MenuItem>): (<></>) }
          </SubMenu>
            
            {user.data?.is_global_admin ? (
            <SubMenu title="Ettevõtted" icon={<FaBuilding />}>
                <MenuItem> <Link to="/companies">Ettevõtete Nimekiri</Link> </MenuItem>
                <MenuItem> <Link to="/companies/invite">Lisa Ettevõte</Link> </MenuItem>
            </SubMenu>
            ) : (<></>)}

            {user.data?.is_global_admin ? (
            <SubMenu title="Kontaktid" icon={<FaPhone />}>
                <MenuItem> <Link to="/contacts">Kontaktide Nimekiri</Link> </MenuItem>
                <MenuItem> <Link to="/contacts/add">Lisa Kontakt</Link> </MenuItem>
                <MenuItem> <Link to="/contacts/import">Impordi kontakte</Link> </MenuItem>
                <MenuItem> <Link to="/contacts/external-protocols">Välised Aegumised</Link> </MenuItem>
            </SubMenu>
            ) : (<></>)}
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        <Menu iconShape="square">
          <MenuItem icon={<FaSignOutAlt />} > <Link to="#" onClick={() => userActions.logout()}>Logi välja</Link> </MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  )
}

export default SideBar;