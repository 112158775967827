import {
  RouteCreateProtocolInput,
  RouteCreateProtocolOutput,
  RouteListProtocolInput,
  RouteListProtocolOutput,
  RouteOneProtocolOutput
} from '../../common/types/route/protocol';
import { apiAction, ApiActionOptions, apiRequest, buildListQuery, Methods } from '../middleware/api';

export function create(data: RouteCreateProtocolInput) {
  return apiRequest<RouteCreateProtocolOutput>({
    path: '/protocol',
    method: Methods.POST,
    body: data
  });
}

export function getOne(id: number) {
  return apiRequest<RouteOneProtocolOutput>({
    path: `/protocol/${id}`,
    method: Methods.GET
  });
}

export function updateProtocolRequest({ id }: {id: number}, data: { contact_id?: number; expires_at: Date; }) {
  return apiRequest<any>({
    path: `/protocol/${id}`,
    method: Methods.PUT,
    body: data
  });
}

export function archive(id: number) {
  return apiRequest<any>({
    path: `/protocol/${id}/archive`,
    method: Methods.POST,
    body: {}
  });
}

export function unarchive(id: number) {
  return apiRequest<any>({
    path: `/protocol/${id}/unarchive`,
    method: Methods.POST,
    body: {}
  });
}

export function createAction(
  { data }: { data: RouteCreateProtocolInput },
  { onSuccess, onFailure }: ApiActionOptions<RouteCreateProtocolOutput>
) {
  return apiAction<RouteCreateProtocolOutput>({
    path: '/protocol',
    method: Methods.POST,
    body: data,
    onSuccess,
    onFailure,
  });
}

export function listAction(
  { query }: {query: Partial<RouteListProtocolInput>},
  { onSuccess, onFailure }: ApiActionOptions<RouteListProtocolOutput>
) {
  let path = '/protocol?';

  return apiAction<RouteListProtocolOutput>({
    method: Methods.GET,
    path: buildListQuery({ path, query }),
    onSuccess,
    onFailure,
  });
}

export function getOneProtocolAction(
  { id }: { id: number },
  { onSuccess, onFailure }: ApiActionOptions<RouteOneProtocolOutput>
) {
  return apiAction<RouteOneProtocolOutput>({
    path: `/protocol/${id}`,
    method: Methods.GET,
    onSuccess,
    onFailure,
  });
}