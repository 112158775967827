import * as Joi from '@hapi/joi';

export const UserUpdatePasswordValidationSchema = Joi.object({
  password: Joi.string().min(6).required().label('Parool'),
  new_password: Joi.string().min(6).required().label('Uus parool'),
  new_password_repeat: Joi.any().valid(Joi.ref('new_password')).required().messages({
    'any.only': 'Parooliväljad peavad olema samad'
  })
});

export const UserUpdateValidationSchema = Joi.object({
  card_nr: Joi.string().min(0).required().label('Töökoja kaardi number'),
});

export const UserInviteSchema = Joi.object({
  email: Joi.string().min(0).required().label('Email'),
  is_admin: Joi.boolean().required().label('Admin'),
});

export const UserSetupSchema = Joi.object({
  hash: Joi.string().min(0).required().label('link'),
  first_name: Joi.string().min(1).required().label('Eesnimi'),
  last_name: Joi.string().min(1).required().label('Perekonnanimi'),
  password: Joi.string().min(6).required().label('Parool'),
  password_repeat: Joi.any().valid(Joi.ref('password')).required().messages({
    'any.only': 'Parooliväljad peavad olema samad'
  })
});

export const UserForgotPasswordSchema = Joi.object({
  email: Joi.string().min(3).required().label('Email')
});

export const UserRecoverPasswordValidationSchema = Joi.object({
  hash: Joi.string().min(0).required().label('link'),
  password: Joi.string().min(6).required().label('Uus parool'),
  password_repeat: Joi.any().valid(Joi.ref('password')).required().messages({
    'any.only': 'Parooliväljad peavad olema samad'
  })
});
