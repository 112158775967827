import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { CompanyListSelectData, RouteCompanyList } from '../../common/types';
import { Dispatch } from 'react';
import { listCompaniesAction } from '../services/company.service';

export interface CompanyListState {
  list: CompanyListSelectData[];
  count: number;
  loading: boolean;
  error: any;
}

let initialState: CompanyListState = {
  list: [],
  count: 0,
  loading: false,
  error: null
};

const slice = createSlice({
  name: 'companyList',
  initialState,
  reducers: {
    companyListPending (state) {
      state.loading = true;
    },
    companyListFulfilled(state, action: PayloadAction<RouteCompanyList>) {
      state.loading = false;
      state.count = action.payload.count;
      state.list = action.payload.items;
      state.error = undefined;
    },
    companyListRejected(state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.list = [];
      state.count = 0;
    }
  },
});

export function listCompanies() {
  return (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.companyListPending);
    const action = listCompaniesAction({
      onSuccess: (r) => dispatch(slice.actions.companyListFulfilled(r)),
      onFailure: (e) => dispatch(slice.actions.companyListRejected(e))
    });
    dispatch(action);
  }
}

export default slice;