import { combineReducers } from 'redux';

import clientCreateSlice from './clientCreateSlice';
import userSlice from './userSlice';
import protocolCreateSlice from './protocolCreateSlice';
import protocolListSlice from './protocolListSlice';
import protocolOneSlice from './protocolOneSlice';
import clientListSlice from './clientListSlice';
import clientOneSlice from './clientOneSlice';
import selectedClientSlice from './selectedClientSlice';
import userUpdateSlice from './userUpdateSlice';
import companyUpdateSlice from './companyUpdateSlice';
import companyProtocolCounterUpdateSlice from './companyProtocolCounterUpdateSlice';
import companyOneSlice from './companyOneSlice';
import companyOneUserSlice from './companyOneUserSlice';
import companyUserListSlice from './companyUserListSlice';
import companyListSlice from './companyListSlice';
import contactListSlice from './contactListSlice';
import contactOneSlice from './contactOneSlice';
import externalProtocolListSlice from './externalProtocolListSlice';

export const sliceActions = {
  clientCreateActions: clientCreateSlice.actions,
  userActions: userSlice.actions,
  protocolCreateActions: protocolCreateSlice.actions,
  protocolListActions: protocolListSlice.actions,
  protocolOneActions: protocolOneSlice.actions,
  clientListActions: clientListSlice.actions,
  clientOneActions: clientOneSlice.actions,
  selectedClientActions: selectedClientSlice.actions,
  userUpdateActions: userUpdateSlice.actions,
  companyUpdateActions: companyUpdateSlice.actions,
  companyOneActions: companyOneSlice.actions,
  companyOneUserActions: companyOneUserSlice.actions,
  companyProtocolCounterUpdateActions: companyProtocolCounterUpdateSlice.actions,
  companyUserListActions: companyUserListSlice.actions,
  companyListActions: companyListSlice.actions,
  contactListActions: contactListSlice.actions,
  contactOneActions: contactOneSlice.actions,
  externalProtocolListActions: externalProtocolListSlice.actions
};

export default combineReducers({
  clientCreateState: clientCreateSlice.reducer,
  userState: userSlice.reducer,
  protocolCreateState: protocolCreateSlice.reducer,
  protocolListState: protocolListSlice.reducer,
  protocolOneState: protocolOneSlice.reducer,
  clientListState: clientListSlice.reducer,
  clientOneState: clientOneSlice.reducer,
  selectedClientState: selectedClientSlice.reducer,
  userUpdateState: userUpdateSlice.reducer,
  companyUpdateState: companyUpdateSlice.reducer,
  companyOneState: companyOneSlice.reducer,
  companyOneUserState: companyOneUserSlice.reducer,
  companyProtocolCounterUpdateState: companyProtocolCounterUpdateSlice.reducer,
  companyUserListState: companyUserListSlice.reducer,
  companyListState: companyListSlice.reducer,
  contactListState: contactListSlice.reducer,
  contactOneState: contactOneSlice.reducer,
  externalProtocolListState: externalProtocolListSlice.reducer
});
