
export enum TempTokenType {
  INVITATION = 'invitation',
  FORGOT_PASSWORD = 'forgot_password'
}

export interface CreateTempTokenData {
  user_id: number;
  hash: string;
  type: TempTokenType;
}

export interface SelectTempTokenData {
  user_id: number;
  hash: string;
  is_used: boolean;
  type: TempTokenType;
  created_at: number;
}