import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { RootState } from '../../lib/store';

const PrivateRoute = (props: any) => {
  // @ts-ignore
  const { component: Component, ...rest } = props;

  const isLoggedInState = useSelector((state: RootState) => state.userState.isLoggedIn);
  const isUserLoading = useSelector((state: RootState) => state.userState.loginLoading);
  const redirectContinuePath = props.path;

  if (isUserLoading) {
    return (<div className="page-loading"><Spinner animation="grow" /></div>)
  }

  return (
    <Route {...rest} render={(props) => {
      if (isLoggedInState) {
        return <Component {...props} />
      }

      return <Redirect to={{
        pathname: '/login',
        state: {
          redirectPath: redirectContinuePath
        }
      }} />
    }}/>
  )
};

export default PrivateRoute;
