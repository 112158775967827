import * as Joi from '@hapi/joi';

export const ContactCreateValidationSchema = Joi.object({
  name: Joi.string().min(0).required().label('Nimi'),
  email: Joi.string().min(0).label('Email'),
  phone: Joi.string().min(0).label('Telefon')
});

export const ContactUpdateValidationSchema = Joi.object({
  name: Joi.string().min(0).required().label('Nimi'),
  email: Joi.string().min(0).label('Email'),
  phone: Joi.string().min(0).label('Telefon')
});

export const ContactSendSmsValidationSchema = Joi.object({
  text: Joi.string().min(1).required().label('SMS'),
});
