import {
  ListQuery,
  ProtocolSelectWithClientData,
  RouteListProtocolOutput
} from '../../common/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { Dispatch } from 'react';
import { listAction } from '../services/protocol.service';

export interface ProtocolListState {
  list: ProtocolSelectWithClientData[],
  count: number;
  loading: boolean;
  error: any;
}

let initialState: ProtocolListState = {
  list: [],
  count: 0,
  loading: false,
  error: null
};

const slice = createSlice({
  name: 'protocolList',
  initialState,
  reducers: {
    protocolListPending (state) {
      state.loading = true;
    },
    protocolListFulfilled(state, action: PayloadAction<RouteListProtocolOutput>) {
      state.loading = false;
      state.count = action.payload.count;
      state.list = action.payload.items;
      state.error = undefined;
    },
    protocolListRejected(state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.list = [];
      state.count = 0;
    }
  },
});

export function listProtocol(query: Partial<ListQuery>) {
  return (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.protocolListPending);
    const action = listAction({ query }, {
      onSuccess: (r) => dispatch(slice.actions.protocolListFulfilled(r)),
      onFailure: (e) => dispatch(slice.actions.protocolListRejected(e))
    });
    dispatch(action);
  }
}

export default slice;