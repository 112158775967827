import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { ClientSelectData, RouteOneClientOutput } from '../../common/types';

export interface ClientOneState {
  data?: ClientSelectData;
  loading: boolean;
  error: any;
}

let initialState: ClientOneState = {
  data: undefined,
  loading: false,
  error: null
};

const slice = createSlice({
  name: 'clientOne',
  initialState,
  reducers: {
    clientOnePending (state) {
      state.loading = true;
    },
    clientOneFulfilled (state, action: PayloadAction<RouteOneClientOutput>) {
      state.loading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    clientOneRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
  },
});

export default slice;
