
export enum ProtocolType {
  INSTALL = 'install',
  PERIODIC = 'periodic',
  MAINTAIN_INSTALL = 'maintain_and_install'
}

export enum SmartType {
  GOODS = 'goods',
  PASSENGER = 'passenger'
}

export interface ProtocolInsertData {
  client_id: number;
  contact_id?: number;
  user_id: number;
  company_id: number;
  generated_id: number;
  type: ProtocolType;
  odometer_before: number;
  odometer_after: number;
  tire_size: string;
  tire_type: string;
  tire_pressure: number;
  tire_circumference: number;
  vehicle_coefficient: number;
  tachograph_constant: number;
  speed_deviation: number;
  distance_deviation: number;
  time_deviation: number;
  speed_limiter: boolean;
  speed_limiter_top_speed?: number;
  diagram_card_archived: boolean;
  tachograph_archived: boolean;
  manipulation_device_found: boolean;
  tachograph_type: string;
  tachograph_id: string;

  expires_at: Date;
  smart: boolean;
  smart_type?: SmartType;
  smart_dsrc_sn?: string;
  smart_seal_nr?: string;
  smart_seal_location?: string;
  smart_gnss_verified?: boolean;
}

export interface ProtocolSelectData {
  id: number;
  client_id: number;
  contact_id?: number;
  user_id: number;
  generated_id: number;
  company_id: number;
  type: ProtocolType;
  odometer_before: number;
  odometer_after: number;
  tire_size: string;
  tire_type: string;
  tire_pressure: number;
  tire_circumference: number;
  vehicle_coefficient: number;
  tachograph_constant: number;
  speed_deviation: number;
  distance_deviation: number;
  time_deviation: number;
  speed_limiter: boolean;
  speed_limiter_top_speed?: number;
  diagram_card_archived: boolean;
  tachograph_archived: boolean;
  tachograph_type: string;
  tachograph_id: string;
  manipulation_device_found: boolean;
  created_at: any;
  archived: boolean;

  expires_at: Date;
  smart: boolean;
  smart_type?: SmartType;
  smart_dsrc_sn?: string;
  smart_seal_nr?: string;
  smart_seal_location?: string;
  smart_gnss_verified?: boolean;
}

export interface ProtocolSelectWithClientData {
  id: number;
  client_id: number;
  contact_id?: number;
  generated_id: number;

  client_driver: string;
  client_general_data: string;
  client_vin: string;
  client_license: string;
  client_email: string;
  client_phone: string;

  manipulation_device_found: boolean;
  user_id: number;
  company_id: number;
  type: ProtocolType;
  odometer_before: number;
  odometer_after: number;
  tire_size: string;
  tire_type: string;
  tire_pressure: number;
  tire_circumference: number;
  vehicle_coefficient: number;
  tachograph_constant: number;
  speed_deviation: number;
  distance_deviation: number;
  time_deviation: number;
  speed_limiter: boolean;
  speed_limiter_top_speed?: number;
  diagram_card_archived: boolean;
  tachograph_archived: boolean;
  tachograph_type: string;
  tachograph_id: string;
  created_at: any;
  archived: boolean;
  expires_at: Date;

  smart: boolean;
  smart_type?: SmartType;
  smart_dsrc_sn?: string;
  smart_seal_nr?: string;
  smart_seal_location?: string;
  smart_gnss_verified?: boolean;
}

export interface ProtocolSelectForPDF {
  id: number;
  generated_id: number;
  driver: string;
  type: string;
  vehicle_general_data: string;
  vehicle_vin: string;
  vehicle_license: string;
  created_at: any;
  card_nr: string;
  odometer_before: number;
  odometer_after: number;
  tire_size: string;
  tire_type: string;
  tire_pressure: number;
  tire_circumference: number;
  vehicle_coefficient: number;
  tachograph_constant: number;
  speed_deviation: number;
  distance_deviation: number;
  time_deviation: number;
  speed_limiter: boolean;
  speed_limiter_top_speed: number;
  diagram_card_archived: boolean;
  tachograph_archived: boolean;
  tachograph_type: string;
  tachograph_id: string;
  company_seal_nr: string;
  company_name: string;
  company_address: string;
  company_phone: string;
  company_full_address: string;
  user_full_name: string;
  company_country: string;
  manipulation_device_found: boolean;
  company_county: string;
  company_eak: string;

  smart: boolean;
  smart_type?: SmartType;
  smart_type_translated?: string;
  smart_dsrc_sn?: string;
  smart_seal_nr?: string;
  smart_seal_location?: string;
  smart_gnss_verified?: boolean;
  expires_at: Date;
}
