import ResponseCodes from '../common/constants/ResponseCodes';
import { Schema } from '@hapi/joi';

export interface GeneralErrorData {
  message: string;
}

export interface ValidationErrorData {
  [key: string]: string;
}

export type ApiError = GeneralErrorData | ValidationErrorData;

export function createValidator(schema: Schema) {
  return async function (body: any) {
    try {
      await schema.validateAsync(body, {
        allowUnknown: true,
        abortEarly: false,
        stripUnknown: false
      });
      return { valid: true, error: null };
    } catch (e) {

      if (!e || !e.details) {
        return {
          valid: false,
          error: {
            code: ResponseCodes.INVALID_REQUEST,
            message: e && e.message ? e.message : 'Something went wrong'
          }
        }
      }
      const errorMap = e.details.reduce((eMap: any, detail: any) => {
        eMap[detail.path.join('_')] = detail.message;
        return eMap;
      }, {});

      return {
        valid: false,
        error: {
          code: ResponseCodes.VALIDATION_ERROR,
          validation: errorMap
        }
      }
    }
  }
}
