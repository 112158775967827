import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import {
  RouteUpdateUserCompanyInput,
  UserSelectData
} from '../../common/types';
import { Dispatch } from 'react';
import { updateCompanyAction } from '../services/user.service';
import authSlice from './userSlice';

export interface CompanyUpdateState {
  data?: {user: UserSelectData; token: string};
  loading: boolean;
  error: any;
}

let initialState: CompanyUpdateState = {
  data: undefined,
  loading: false,
  error: undefined
};

const slice = createSlice({
  name: 'companyUpdate',
  initialState,
  reducers: {
    companyUpdatePending (state) {
      state.loading = true;
    },
    companyUpdateFulfilled (state, action: PayloadAction<{user: UserSelectData; token: string}>) {
      state.loading = false;
      state.data = action.payload;
      state.error = undefined;
    },
    companyUpdateRejected (state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
    reset (state) {
      state.data = undefined;
      state.loading = false;
      state.error = undefined;
    }
  },
});

export function updateCompany(data: RouteUpdateUserCompanyInput) {
  return (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.companyUpdatePending());
    const action = updateCompanyAction({ data },
      {
        onSuccess: (r) => {
          dispatch(authSlice.actions.setUser(r));
          dispatch(slice.actions.companyUpdateFulfilled(r));
        },
        onFailure: (e) => dispatch(slice.actions.companyUpdateRejected(e))
      });
    dispatch(action);
  }
}

export default slice;
