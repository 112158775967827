import { ListQuery, RouteContactListOutput, RouteContactCreateOutput, RouteContactCreateInput, RouteContactSendSmsInput, RouteContactUpdateInput, RouteContactUpdateOutput, RouteContactOneOutput, RouteContactExternalProtocolListOutput } from "../../common/types";
import { apiAction, ApiActionOptions, apiRequest, buildListQuery, Methods } from "../middleware/api";

export function listContactAction(
  { query }: {query: Partial<ListQuery>},
  { onSuccess, onFailure }: ApiActionOptions<RouteContactListOutput>
) {
  let path = '/contact?';

  return apiAction<RouteContactListOutput>({
    method: Methods.GET,
    path: buildListQuery({ path, query }),
    onSuccess,
    onFailure,
  });
}

export function listExternalProtocolAction(
  { query }: { query: Partial<ListQuery> },
  { onSuccess, onFailure }: ApiActionOptions<RouteContactExternalProtocolListOutput>
) {
  let path = '/contact/external-protocol?';

  return apiAction<RouteContactExternalProtocolListOutput>({
    method: Methods.GET,
    path: buildListQuery({ path, query }),
    onSuccess,
    onFailure,
  });
}

export function deleteExternalProtocolRequest(
  {id}: {id: number}
) {
  let path = `/contact/external-protocol/${id}`;
  return apiRequest<any>({
    method: Methods.DELETE,
    path
  });
}

export function listContactRequest({ query }: {query: Partial<ListQuery>}) {
  let path = '/contact?';

  return apiRequest<RouteContactListOutput>({
    method: Methods.GET,
    path: buildListQuery({ path, query })
  });
}

export function getOneContactRequest(
  { id }: { id : number }
) {
  let path = `/contact/${id}`;

  return apiRequest<RouteContactOneOutput>({
    method: Methods.GET,
    path
  });
}
export function getOneContactAction(
  { id }: {id : number },
  { onSuccess, onFailure }: ApiActionOptions<RouteContactOneOutput>
) {
  let path = `/contact/${id}`;

  return apiAction<RouteContactOneOutput>({
    method: Methods.GET,
    path,
    onSuccess,
    onFailure,
  });
}

export function createContactRequest(body: RouteContactCreateInput) {
  return apiRequest<RouteContactCreateOutput>({
    path: `/contact`,
    method: Methods.POST,
    body
  });
}

export function importContactsRequest(body: string[][]) {
  return apiRequest<string[]>({
    path: `/contact/import`,
    method: Methods.POST,
    body
  });
}

export function updateContactRequest({id}: {id: number},body: RouteContactUpdateInput) {
  return apiRequest<RouteContactUpdateOutput>({
    path: `/contact/${id}`,
    method: Methods.PUT,
    body
  });
}

export function sendContactSmsRequest({id}: {id: number}, body: RouteContactSendSmsInput) {
  return apiRequest<RouteContactCreateOutput>({
    path: `/contact/${id}/send-sms`,
    method: Methods.POST,
    body
  });
}

export function archiveContactRequest(
  {id}: {id: number}
) {
  return apiRequest<RouteContactCreateOutput>({
    path: `/contact/${id}/archive`,
    method: Methods.POST,
    body: {}
  });
}

export function unarchiveContactRequest(
  {id}: {id: number}
) {
  return apiRequest<RouteContactCreateOutput>({
    path: `/contact/${id}/unarchive`,
    method: Methods.POST,
    body: {}
  });
}