import * as Joi from '@hapi/joi';
import { ClientCreateValidationSchema } from './client';

export const ProtocolCreateValidationSchema = Joi.object({
  client_id: Joi.number().when('client', {not: Joi.exist(), then: Joi.required()}),
  client: ClientCreateValidationSchema.label('Klient'),
  type:  Joi.string().required().valid('install', 'periodic', 'maintain_and_install').label('Kontrolli Tüüp'),
  odometer_before: Joi.number().min(0).required().label('Odomeeter Enne'),
  odometer_after: Joi.number().min(0).required().label('Odomeeter Pärast').when('odometer_before', {then: Joi.number().min(Joi.ref('odometer_before'))}),
  tire_size: Joi.string().min(0).required().label('Rehvi suurus'),
  tire_type: Joi.string().min(0).required().label('Rehvi tüüp'),
  tire_pressure: Joi.number().min(0).required().label('Rehvirõhk'),
  tire_circumference: Joi.number().min(0).required().label('Rehvi ümbermõõt'),
  vehicle_coefficient: Joi.number().min(0).required().label('Sõiduki tunnustegur'),
  tachograph_constant: Joi.number().min(0).required().label('Sõidumeeriku konstant'),
  speed_deviation: Joi.number().required().label('Kiiruse hälve'),
  distance_deviation: Joi.number().required().label('Vahemaa hälve'),
  time_deviation: Joi.number().required().label('Aja hälve'),
  speed_limiter: Joi.boolean().required().label('Kiiruspiiraja olemasolu määramine'),
  diagram_card_archived: Joi.boolean().required().label('Diagrammkettas joonistatus ja arhiveeritud määramine'),
  tachograph_archived: Joi.boolean().required().label('Digimeeriku andmed arhiveeritud määramine'),
  tachograph_type: Joi.string().min(0).required().label('Sõidumeeriku tüüp'),
  tachograph_id: Joi.string().min(0).required().label('Sõidumeeriku number'),
  speed_limiter_top_speed: Joi.number().min(0).label('Max kiirus'),
  manipulation_device_found: Joi.boolean().required().label('Manipuleerimiseadme olemasolu määramine'),

  expires_at: Joi.any().required().label('Kontroll aegub'),

  smart: Joi.boolean().required().label('Aruka sõidumeeriku olemasolu määramine'),
  smart_type:  Joi.string().valid('goods', 'passenger').label('Veose liik'),
  smart_dsrc_sn: Joi.string().label('DSRC Seerianumber').when('smart', {is: true, then: Joi.required()}),
  smart_seal_nr: Joi.string().length(10).label('SMART plommi seerianumber').when('smart', {is: true, then: Joi.required()}),
  smart_seal_location: Joi.string().label('SMART plommi asukoht').when('smart', {is: true, then: Joi.required()}),
  smart_gnss_verified: Joi.boolean().required().label('GNSS kontrollitud').when('smart', {is: true, then: Joi.required()})
});
