import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { Row, Col, Table, Tab, Tabs, Form, Container } from 'react-bootstrap';
import qs from 'query-string';
import useDebounce from '../../lib/hooks/useDebounce';
import { RootState } from '../../lib/store';
import SmPagination from '../../components/form/SmPagination';
import useApiAction from '../../lib/hooks/useApiAction';
import { Link } from 'react-router-dom';
import { FaTrash, FaTrashRestore } from 'react-icons/fa';
import { archiveContactRequest, unarchiveContactRequest } from '../../lib/services/contact.service';

const LIMIT = 50;

function ContactList () {
  const location = useLocation();
  const history = useHistory();
  const {page} = qs.parse(location.search);
  const user = useSelector((state: RootState) => state.userState);
  const contactList = useSelector((state: RootState) => state.contactListState);

  const [pageNumber, setPageNumber] = useState<number>(page ? +page : 1);
  const [filterByArchived, setFilterByArchived] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { listContact } = useApiAction();

  const refresh = useCallback(() => {
    return listContact({
        limit: LIMIT,
        offset: (pageNumber -1) * LIMIT,
        filter: JSON.stringify({
          archived: filterByArchived
        }),
        search: debouncedSearchTerm
      });
  }, [listContact, pageNumber, filterByArchived, debouncedSearchTerm]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    setSearchTerm('');
  }, [filterByArchived]);


  function goToPage(p: number) {
    history.push({
      pathname: '/contacts',
      search: `?page=${p}`
    });
    setPageNumber(p);
  }

  async function setContactArchived(contactId: number) {
    try {
      await archiveContactRequest({id: contactId});
      refresh();
    } catch (e) {}
  }

  async function setContactUnarchived(contactId: number) {
    try {
      await unarchiveContactRequest({id: contactId});
      refresh();
    } catch (e) {}
  }

  return (
    <>
      <h1>
        {filterByArchived ? 'Kustutatud kontaktid' : 'Kontaktid'}
      </h1>
      <Container>
        <Row className="mb-3 justify-content-end">
          <Link to="/contacts/import" className="btn btn-primary mr-3">Impordi</Link>
          <Link to="/contacts/add" className="btn btn-primary">Lisa kontakt</Link>
        </Row>
      </Container>
      <Tabs
        id="controlled-tab"
        activeKey={filterByArchived ? 'archive' : 'contacts'}
        onSelect={(k: string) =>
          setFilterByArchived(k === 'archive')
        }
      >
        <Tab eventKey="contacts" title="Kontaktid" />
        <Tab eventKey="archive" title="Arhiiv" />
      </Tabs>
      <Form.Group controlId="search" className={'mt-3'}>
        <Form.Control
          type="text"
          value={searchTerm}
          placeholder="Otsi kontakti"
          onChange={(e) => setSearchTerm(e.target.value)}
          onBlur={() => refresh()}
        />
      </Form.Group>

      <>
        <Row>
          <Col>
            <Table className={'table-sm'}>
              <thead className="thead-light">
              <tr>
                <th className={'text-muted'}>#</th>
                <th>Klient</th>
                <th>Email</th>
                <th className="d-none d-md-table-cell">Telefon</th>
                <th />
              </tr>
              </thead>
              <tbody>
              {contactList.list.map((c) => {
                return <tr key={c.id}>
                <td className={'text-muted'}>{c.id}</td>
                <td><Link to={`/contacts/edit/${c.id}`}>{c.name}</Link></td>
                <td>{c.email}</td>
                <td>{c.phone}</td>
                <td>
                  {
                    !c.archived ? (
                      <span style={{marginRight: '0.3em', cursor: 'pointer'}} onClick={() => setContactArchived(c.id)}>
                        <FaTrash style={{verticalAlign: 'unset'}} className={'btn-link'} />
                      </span>
                    ) : (
                      <span style={{marginRight: '0.3em', cursor: 'pointer'}} onClick={() => setContactUnarchived(c.id)}>
                        <FaTrashRestore style={{verticalAlign: 'unset'}} className={'btn-link'} />
                      </span>
                    )
                  }
                </td>
              </tr>
              })}
              </tbody>
            </Table>
          </Col>
        </Row>

        <SmPagination count={contactList.count} pageNumber={pageNumber} goToPage={(pnr) => goToPage(pnr)} limit={LIMIT}/>
      </>
    </>
  );
}

export default ContactList;
