import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import FormElement from '../../components/form/FormElement';
import { createValidator } from '../../lib/core';
import { UserInviteSchema } from '../../common/validators/user';
import { inviteCompanyUser } from '../../lib/services/company.service';

const userInviteValidator = createValidator(UserInviteSchema);

function AddCompanyUser () {
  const history = useHistory();
  const { companyId } = useParams<any>();
  const [errors, setErrors] = useState<any>();
  const [email, setEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function validate() {
      const {error} = await userInviteValidator({ email, is_admin: isAdmin });

      setErrors(error);
    }

    validate();
  }, [email, isAdmin]);

  async function handleFormSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    try {
      const {error} = await inviteCompanyUser(companyId, {email, is_admin: isAdmin});
      setErrors(error);
      setIsLoading(false);

      if (!error) {
        history.push(`/company/${companyId}/user-list`)
      }
    } catch (e) {
      setIsLoading(false);
    }
  }
  return (
    <>
      <h1>Lisa Kasutaja</h1>

      <Form onSubmit={handleFormSubmit}>
        <div className="form-section">
          <FormElement errors={errors} value={email} label={'Email'} validationKey={'email'} type={'email'} onChange={(v) => { setEmail(v);}}/>

          <FormElement errors={errors} value={isAdmin} label={'Admin'} validationKey={'is_admin'} type={'switch'} onChange={(v) => { setIsAdmin(v);}}/>
        </div>
        <Button type="submit" className={'btn-primary'} disabled={isLoading || !!errors} block >Saada kutse</Button>
      </Form>
    </>
  );
}

export default AddCompanyUser;
