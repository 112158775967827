import React, { useEffect } from 'react';
import { Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import SmLoading from '../../components/form/SmLoading';
import useApiAction from '../../lib/hooks/useApiAction';
import { useSelector } from 'react-redux';
import { RootState } from '../../lib/store';
import { Link, useParams } from 'react-router-dom';
import CompanyUserListRow from './components/CompanyUserListRow';
import { FaEnvelope, FaTrash, FaTrashRestore, FaSync } from 'react-icons/fa';

function CompanyUserList () {
  const { listCompanyUsers } = useApiAction();
  const { companyId } = useParams();

  const userList = useSelector((state: RootState) => state.companyUserListState);
  useEffect(() => {
    listCompanyUsers(companyId);
  }, [listCompanyUsers]);

  if (userList.loading) {
    return <SmLoading />;
  }

  return (
    <>
      <h1>Kasutajad</h1>
      <Row className="mb-3">
        <Col className="col-sm-12"><Link to={`/company/${companyId}/user-list/invite`} className="btn btn-primary float-right">Lisa kasutaja</Link></Col>
      </Row>
      <Row>
        <Col>
          <Table className={'table-sm'}>
            <thead className="thead-light">
            <tr>
              <th>Nimi</th>
              <th>Email</th>
              <th>Admin</th>
              <th />
            </tr>
            </thead>
            <tbody>
            { userList.list.map((u) => <CompanyUserListRow key={u.id} user={u}/>) }
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col className={'text-sm'}>
          <span>
            <FaEnvelope />
          </span>
          <span className={'text-muted small'} > - ligipääsu lingiga kiri saadetud, aga kasutaja kinnitamata</span>
        </Col>
      </Row>
      <Row>
        <Col className={'text-sm'}>
          <span>
            <FaSync className={'btn-link'} />
          </span>
          <span className={'text-muted small'} > - vajuta, et saata uus kutse (vana kutselink aegub)</span>
        </Col>
      </Row>
      <Row>
        <Col className={'text-sm'}>
          <span>
            <FaTrash className={'btn-link'} />
          </span>
          <span className={'text-muted small'} > - vajuta, et eemaldada kasutaja ligipääs</span>
        </Col>
      </Row>
      <Row>
        <Col className={'text-sm'}>
          <span>
            <FaTrashRestore className={'btn-link'} />
          </span>
          <span className={'text-muted small'} > - vajuta, et taastada kasutaja ligipääs</span>
        </Col>
      </Row>
    </>
  );
}

export default CompanyUserList;
