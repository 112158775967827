import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import './Login.css';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { forgotPasswordRequest } from '../../lib/services/user.service';
import ValidationErrorComponent from '../../components/app/ValidationError';
import { toast, ToastContainer } from 'react-toastify';

function ForgotPassword () {
  const [email, setEmail] = useState<string>('');
  const [recoverError, setError] = useState<any>();
  const [sent, setSent] = useState(false);

  async function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    const {error} = await forgotPasswordRequest({email});

    setError(error);
    if (!error) {
      setSent(true);
      toast.info('Parooli taastamiseks E-kiri saadetud');
    }
  }

  return (
    <Container className=''>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        style={{ width: "100%" }}
        className={'text-center'}
      />
      <h1 className='text-center'>Taasta kasutaja</h1>
      <Row className='d-flex mt-5'>
        <Col sm={9} lg={5} md={7} className="mx-auto">
          <Form className="form-signin" onSubmit={onSubmit}>
            <Form.Group className="form-label-group">
              <Form.Control type="email" id="inputEmail" className="form-control" placeholder="Email" onChange={(e: React.ChangeEvent<HTMLInputElement>) => (setEmail(e.target.value))} required autoFocus />
              <Form.Label htmlFor={"inputEmail"}>Email</Form.Label>
              <ValidationErrorComponent hide={false} name={'email'} errors={recoverError} />
            </Form.Group>
            <Button className="btn btn-lg btn-primary btn-block" type="submit" disabled={sent}>Taasta parool</Button>
          </Form>
        </Col>
      </Row>
      <Row className={'mt-2'}>
        <Col sm={9} lg={5} md={7} className={'text-right mx-auto small'}>
          <Link to={'/login'}>Logi sisse</Link>
        </Col>
      </Row>
    </Container>
  )
}

export default ForgotPassword;
