import {
  CompanySelectData, RouteAcceptInvitationInput, RouteAcceptInvitationOutput,
  RouteLoginInput,
  RouteLoginOutput, RouteRecoverPasswordInput, RouteRecoverPasswordOutput,
  RouteUpdateUserCompanyInput,
  RouteUpdateUserCompanyProtocolCounterInput,
  RouteUpdateUserInput,
  RouteUpdateUserPasswordInput
} from '../../common/types';
import { apiAction, ApiActionOptions, apiRequest, Methods } from '../middleware/api';

export function forgotPasswordRequest(data: {email: string;}) {
  return apiRequest<any>({
    path: `/forgot-password`,
    method: Methods.POST,
    body: data
  });
}

export function loginAction(
  { data }: { data: RouteLoginInput },
  { onSuccess, onFailure }: ApiActionOptions<RouteLoginOutput>
) {
  return apiAction<RouteLoginOutput>({
    path: '/login',
    method: Methods.POST,
    body: data,
    onSuccess,
    onFailure
  })
}

export function acceptInvitationAction(
  { data }: { data: RouteAcceptInvitationInput },
  { onSuccess, onFailure }: ApiActionOptions<RouteAcceptInvitationOutput>
) {
  return apiAction<RouteAcceptInvitationOutput>({
    path: '/accept-invitation',
    method: Methods.POST,
    body: data,
    onSuccess,
    onFailure
  })
}

export function recoverPasswordAction(
  { data }: { data: RouteRecoverPasswordInput },
  { onSuccess, onFailure }: ApiActionOptions<RouteRecoverPasswordOutput>
) {
  return apiAction<RouteRecoverPasswordOutput>({
    path: '/recover-password',
    method: Methods.POST,
    body: data,
    onSuccess,
    onFailure
  })
}

export function updateUserPasswordAction(
  { data }: { data: RouteUpdateUserPasswordInput },
  { onSuccess, onFailure }: ApiActionOptions<RouteLoginOutput>
) {
  return apiAction<RouteLoginOutput>({
    path: '/user/password',
    method: Methods.PUT,
    body: data,
    onSuccess,
    onFailure
  })
}

export function updateUserRequest({ data }: { data: RouteUpdateUserInput }) {
  return apiRequest<void>({
    path: '/user',
    method: Methods.PUT,
    body: data,
  })
}

export function updateCompanyProtocolCounterAction(
  { data }: { data: RouteUpdateUserCompanyProtocolCounterInput },
  { onSuccess, onFailure }: ApiActionOptions<RouteLoginOutput>
) {
  return apiAction<RouteLoginOutput>({
    path: '/user/company/protocol-counter',
    method: Methods.PUT,
    body: data,
    onSuccess,
    onFailure
  })
}

export function updateCompanyAction(
  { data }: { data: RouteUpdateUserCompanyInput },
  { onSuccess, onFailure }: ApiActionOptions<RouteLoginOutput>
) {
  return apiAction<RouteLoginOutput>({
    path: '/user/company',
    method: Methods.PUT,
    body: data,
    onSuccess,
    onFailure
  })
}

export function getFullCompanyAction(
  { onSuccess, onFailure }: ApiActionOptions<CompanySelectData>
) {
  return apiAction<CompanySelectData>({
    path: '/user/company',
    method: Methods.GET,
    onSuccess,
    onFailure
  })
}
