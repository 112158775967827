import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ValidationErrorComponent from '../app/ValidationError';

interface ClientEditData {
  id?: number,
  driver: string;
  general_data: string;
  vin: string;
  license: string;
  // email: string;
  // phone: string;
};

const initialClientState = {
  driver: '',
  general_data: '',
  vin: '',
  license: '',
  // email: '',
  // phone: ''
};

export default function ClientFields (props: {
  client: ClientEditData;
  errors: any;
  disabled?: boolean
  onChange: (client: ClientEditData) => void
}) {
  const [isVinFocused, setIsVinFocused] = useState<boolean>(false);
  const [valuesTouched, setValuesTouched] = useState<any>({});

  function extendClientData(e: React.ChangeEvent<HTMLInputElement>) {
    setValuesTouched({
      ...valuesTouched,
      [`client_${e.target.id}`]: true
    });

    props.onChange({
      ...props.client,
      [e.target.id]: e.target.value
    })
  }

  return (<>
    <Row>
      <Col xs={12} sm={6}>
        <Form.Group controlId="driver">
          <Form.Label>Sõiduki omanik/kasutaja</Form.Label>
          <Form.Control type="text" value={props.client?.driver} onChange={extendClientData} disabled={props.disabled}/>
          <ValidationErrorComponent hide={!valuesTouched['client_driver']} name={'client_driver'} errors={props.errors} />
        </Form.Group>
      </Col>
      <Col xs={12} sm={6}>
        <Form.Group controlId="general_data">
          <Form.Label>Sõiduki mark ja mudel</Form.Label>
          <Form.Control type="text" value={props.client?.general_data} onChange={extendClientData} disabled={props.disabled} />
          <ValidationErrorComponent hide={!valuesTouched['client_general_data']} name={'client_general_data'} errors={props.errors} />
        </Form.Group>
      </Col>
      <Col xs={12} sm={6}>
        <Form.Group controlId="vin">
          <Form.Label>VIN</Form.Label>
          <Form.Control
            type="text"
            value={props.client?.vin}
            onChange={extendClientData}
            onFocus={() => setIsVinFocused(true)}
            onBlur={() => setIsVinFocused(false)}
            disabled={props.disabled}
          />
          <ValidationErrorComponent hide={!valuesTouched['client_vin'] || isVinFocused} name={'client_vin'} errors={props.errors} />
        </Form.Group>
      </Col>
      <Col xs={12} sm={6}>
        <Form.Group controlId="license">
          <Form.Label>Registreerimismärk</Form.Label>
          <Form.Control type="text" value={props.client?.license} onChange={extendClientData} disabled={props.disabled} />
          <ValidationErrorComponent hide={!valuesTouched['client_license']} name={'client_license'} errors={props.errors} />
        </Form.Group>
      </Col>
      {/* <Col xs={12} sm={6}>
        <Form.Group controlId="phone">
          <Form.Label>Kontakt Tel.</Form.Label>
          <Form.Control type="text" value={props.client?.phone} onChange={extendClientData} disabled={props.disabled} />
          <ValidationErrorComponent hide={!valuesTouched['client_phone']} name={'client_phone'} errors={props.errors} />
        </Form.Group>
      </Col>
      <Col xs={12} sm={6}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" value={props.client?.email} onChange={extendClientData} disabled={props.disabled} />
          <ValidationErrorComponent hide={!valuesTouched['client_email']} name={'client_email'} errors={props.errors} />
        </Form.Group>
      </Col> */}
    </Row>
  </>)
}
