import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { CompanyUserData, RouteUserCompanyList } from '../../common/types';
import { Dispatch } from 'react';
import { listCompanyUserAction } from '../services/company.service';

export interface CompanyUserListState {
  list: CompanyUserData[];
  count: number;
  loading: boolean;
  error: any;
}

let initialState: CompanyUserListState = {
  list: [],
  count: 0,
  loading: false,
  error: null
};

const slice = createSlice({
  name: 'companyUserList',
  initialState,
  reducers: {
    companyUserListPending (state) {
      state.loading = true;
    },
    companyUserListFulfilled(state, action: PayloadAction<RouteUserCompanyList>) {
      state.loading = false;
      state.count = action.payload.count;
      state.list = action.payload.items;
      state.error = undefined;
    },
    companyUserListRejected(state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.list = [];
      state.count = 0;
    }
  },
});

export function listCompanyUsers(companyId: number) {
  return (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.companyUserListPending);
    const action = listCompanyUserAction({
      companyId
    },{
      onSuccess: (r) => dispatch(slice.actions.companyUserListFulfilled(r)),
      onFailure: (e) => dispatch(slice.actions.companyUserListRejected(e))
    });
    dispatch(action);
  }
}

export default slice;