import React, { useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { ContactTableFields } from '../../common/types';
import { getOneContactRequest, listContactRequest } from '../../lib/services/contact.service';

export interface SearchValue {
  value?: number;
  label?: string;
}

interface ContactSearchProps extends SearchValue {
  onChange: (v: SearchValue) => void
}

function formatLabel(contact: ContactTableFields) {
  return `${contact.name} | ${contact.email}`
}

function ContactSearch (props: ContactSearchProps) {

  function onChange (contact: any) {
    props.onChange({
      value: contact?.value,
      label: contact?.label
    });
  }

  useEffect(() => {
    async function getContactValues(id: number) {
      const { response } = await getOneContactRequest({id});

      if (response) {
        props.onChange({
          value: response.id,
          label: formatLabel(response)
        });
      }

    }

    if (props.value && !props.label) {
      getContactValues(props.value);
    }
  }, [props.label, props.value]);

  async function promiseOptions(inputValue: string) {
    const val = await listContactRequest({ query: {search: inputValue, limit: 5 }})
    return val.response?.items.map((item) => ({
      value: item.id,
      label: formatLabel(item)
    }));
  }

  return <AsyncSelect loadOptions={promiseOptions} value={{
    value: props.value,
    label: props.label
  }} onChange={onChange} isClearable placeholder="Otsi andmebaasist..."/>
}

export default ContactSearch;
