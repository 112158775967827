import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, InputGroup, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { RouteCreateProtocolInput, SmartType } from '../../common/types';
import { Container } from 'react-bootstrap';
import ClientFields from '../../components/client/ClientFields';
import { ProtocolType } from '../../common/types';
import ValidationErrorComponent from '../../components/app/ValidationError';
import { createValidator } from '../../lib/core';
import { ProtocolCreateValidationSchema } from '../../common/validators/protocol';
import FormElement from '../../components/form/FormElement';
import Select from 'react-select';
import { RootState } from '../../lib/store';
import useApiAction from '../../lib/hooks/useApiAction';
import useSliceAction from '../../lib/hooks/useSliceAction';
import SmDatePicker from '../../components/form/SmDatePicker';
import moment from 'moment';

const protocolCreateValidator = createValidator(ProtocolCreateValidationSchema);

interface ProtocolCreateData {
  type?: ProtocolType;
  odometer_before?: number;
  odometer_after?: number;
  tire_size?: string;
  tire_type?: string;
  tire_pressure?: number;
  tire_circumference?: number;
  vehicle_coefficient?: number;
  tachograph_constant?: number;
  speed_deviation?: number;
  distance_deviation?: number;
  time_deviation?: number;
  speed_limiter?: boolean;
  speed_limiter_top_speed?: number;
  diagram_card_archived?: boolean;
  tachograph_archived?: boolean;
  tachograph_type?: string;
  tachograph_id?: string;
  manipulation_device_found?: boolean;

  smart?: boolean;
  smart_type?: SmartType;
  smart_dsrc_sn?: string;
  smart_seal_nr?: string;
  smart_seal_location?: string;
  smart_gnss_verified?: boolean;
}

const TIRE_TYPES = [
  { value: 'Radiaal', label: 'Radiaal' },
  { value: 'Diagonaal', label: 'Diagonaal' },
];

const SMART_SEAL_LOCATION = [
  { value: 'Kiiruse andur', label: 'Kiiruse andur' },
  { value: 'Sõidumeerik', label: 'Sõidumeerik' },
  { value: 'M1N1 adapter', label: 'M1N1 adapter' },
  { value: 'GNSS moodul', label: 'GNSS moodul' },
  { value: 'DSRC moodul', label: 'DSRC moodul' },
  { value: 'ITS moodul', label: 'ITS moodul' },
  { value: 'Plagu', label: 'Plagu' },
  { value: 'Väline GNSS', label: 'Väline GNSS' },
  { value: 'Puudub', label: 'Puudub' },
]

const SMART_TYPES = [
  { value: 'goods', label: 'Kaup' },
  { value: 'passenger', label: 'Inimesed' }
];

function Protocol () {
  const { createProtocol } = useApiAction();
  const { protocolCreateActions, selectedClientActions } = useSliceAction();
  const history = useHistory();
  const protocolCreate = useSelector((state: RootState) => state.protocolCreateState);
  const selectedClient = useSelector((state: RootState) => state.selectedClientState);

  const [protocolCreateData, setProtocolCreateData] = useState<ProtocolCreateData>({
    type: ProtocolType.PERIODIC,
    speed_limiter: false,
    diagram_card_archived: false,
    tachograph_archived: false,
    manipulation_device_found: false,
    smart: false,
    smart_gnss_verified: false,
    tire_type: TIRE_TYPES[0].value,
  });
  const [clientData, setClientData] = useState<any>({
    driver: '',
    general_data: '',
    vin: '',
    license: ''
  });
  const [expiresAt, setExpiresAt] = useState(moment().add(2, 'years').toDate());
  const [valuesTouched, setValuesTouched] = useState<any>({});

  const [hasError, setHasError] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>(null);

  useEffect(() => {
    selectedClientActions.resetClient();
    protocolCreateActions.reset();

    return () => {
      selectedClientActions.resetClient();
      protocolCreateActions.reset();
    }
  }, [])

  useEffect(() => {
    if (selectedClient.client) {
      setClientData(selectedClient.client);
    }
  }, [selectedClient.client]);

  useEffect(() => {
    if (protocolCreateData.smart) {
      setProtocolCreateData({
        ...protocolCreateData,
        smart_seal_location: SMART_SEAL_LOCATION[0].value,
        // @ts-ignore
        smart_type: SMART_TYPES[0].value as SmartType
      });
    } else {
      setProtocolCreateData({
        ...protocolCreateData,
        smart_seal_location: undefined,
        smart_type: undefined
      });
    }
  }, [protocolCreateData.smart])
  useEffect(() => {
    if (protocolCreate.data?.id) {
      history.push('/')
    }
  }, [protocolCreate.data]);

  useEffect(() => {
    async function validate () {
      const {valid, error} = await protocolCreateValidator({
        ...protocolCreateData,
        expires_at: expiresAt,
        client_id: clientData.id ? clientData.id : undefined,
        client: clientData
      });

      console.log(error);
      setHasError(!valid);
      setErrors(error);
    }

    validate();
  }, [protocolCreateData, clientData, expiresAt]);

  useEffect(() => {
    if (protocolCreate.error) {
      setHasError(true);
      setErrors(protocolCreate.error);
      setValuesTouched({
        type: true,
        odometer_before: true,
        odometer_after: true,
        tire_size: true,
        tire_type: true,
        tire_pressure: true,
        tire_circumference: true,
        vehicle_coefficient: true,
        tachograph_constant: true,
        speed_deviation: true,
        distance_deviation: true,
        time_deviation: true,
        speed_limiter: true,
        speed_limiter_top_speed: true,
        diagram_card_archived: true,
        tachograph_archived: true,
        tachograph_type: true,
        tachograph_id: true,
        company_seal_nr: true
      });
    }
  }, [protocolCreate.error]);

  function handleFormSubmit(e: React.FormEvent) {
    e.preventDefault();
    const createData = {
      ...protocolCreateData,
      expires_at: expiresAt,
      client: clientData
    };

    createProtocol(createData as RouteCreateProtocolInput);
  }

  function extendProtocolData(e: React.ChangeEvent<HTMLInputElement>) {
    setValuesTouched({
      ...valuesTouched,
      [e.target.id]: true
    });
    setProtocolCreateData({
      ...protocolCreateData,
      [e.target.id]: e.target.value
    });
  }

  function extendFactory<T>(key: string) {
    return (v: T) => {
      setProtocolCreateData({ ...protocolCreateData, [key]: v });
    }
  }

  function extendProtocolBoolData(e: React.ChangeEvent<HTMLInputElement>) {
    setValuesTouched({
      ...valuesTouched,
      [e.target.id]: true
    });
    setProtocolCreateData({
      ...protocolCreateData,
      [e.target.id]: e.target.checked
    });
  }

  return (
    <Container>
      <h1>Koosta Protokoll</h1>

      <Form onSubmit={handleFormSubmit}>
        <div className="form-section">
          <h3>1. Klient</h3>
          <ClientFields onChange={(c) => { setClientData(c) }} client={clientData} errors={errors} />
        </div>

        <div className="form-section">
          <h3>2. Üldandmed</h3>
          <Row>
            <Col xs={6}>
              <Form.Group controlId="type">
                <Form.Label>Kontrolli tüüp</Form.Label>
                <Select
                  options={[
                    {value: ProtocolType.PERIODIC, label: 'Perioodiline'},
                    {value: ProtocolType.INSTALL, label: 'Paigaldus'},
                    {value: ProtocolType.MAINTAIN_INSTALL, label: 'Remont ja paigaldus'},
                  ]}
                  onChange={(v) => {
                    setProtocolCreateData({
                      ...protocolCreateData,
                      //@ts-ignore
                      type: v.value
                    });
                  }}
                  placeholder={'Vali üks'}
                  defaultValue={{value: ProtocolType.PERIODIC, label: 'Perioodiline'}}
                />
                <ValidationErrorComponent hide={!valuesTouched['type']} name={'type'} errors={errors} />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group controlId="expire">
                <Form.Label>Kontroll Aegub</Form.Label>
                <SmDatePicker onChange={(v: Date) => setExpiresAt(v)} selected={expiresAt} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <Form.Group controlId="odometer_before">
                <Form.Label>Odomeeter enne</Form.Label>
                <Form.Control type="number" value={protocolCreateData.odometer_before} onChange={extendProtocolData} />
                <ValidationErrorComponent hide={!valuesTouched['odometer_before']} name={'odometer_before'} errors={errors} />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group controlId="odometer_after">
                <Form.Label>Odomeeter pärast</Form.Label>
                <Form.Control type="number" value={protocolCreateData.odometer_after} onChange={extendProtocolData} />
                <ValidationErrorComponent hide={!valuesTouched['odometer_after']} name={'odometer_after'} errors={errors} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <Form.Group controlId="tachograph_type">
                <Form.Label>Sõidumeeriku tüüp</Form.Label>
                <Form.Control type="text" value={protocolCreateData.tachograph_type} onChange={extendProtocolData} />
                <ValidationErrorComponent hide={!valuesTouched['tachograph_type']} name={'tachograph_type'} errors={errors} />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group controlId="tachograph_id">
                <Form.Label>Sõidumeeriku seerianumber</Form.Label>
                <Form.Control type="text" value={protocolCreateData.tachograph_id} onChange={extendProtocolData} />
                <ValidationErrorComponent hide={!valuesTouched['tachograph_id']} name={'tachograph_id'} errors={errors} />
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div className="form-section">
          <h3>3. Sõiduki andmed</h3>
          <Row>
            <Col xs={12} sm={6}>
              <Form.Group controlId="tire_size">
                <Form.Label>Rehvimõõt</Form.Label>
                <Form.Control type="text" value={protocolCreateData.tire_size} onChange={extendProtocolData} />
                <ValidationErrorComponent hide={!valuesTouched['tire_size']} name={'tire_size'} errors={errors} />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group controlId="tire_type">
                <Form.Label>Rehvi tüüp</Form.Label>
                <Select
                  options={TIRE_TYPES}
                  onChange={(v) => {
                      setProtocolCreateData({
                        ...protocolCreateData,
                        //@ts-ignore
                        tire_type: v.value
                      });
                  }}

                  defaultValue={TIRE_TYPES[0]}
                />
                <ValidationErrorComponent hide={!valuesTouched['tire_type']} name={'tire_type'} errors={errors} />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group controlId="tire_pressure">
                <Form.Label>Rehvirõhk</Form.Label>

                <InputGroup>
                  <Form.Control type="number" step="0.01" value={protocolCreateData.tire_pressure} onChange={extendProtocolData} />
                  <InputGroup.Append>
                    <InputGroup.Text>bar</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <ValidationErrorComponent hide={!valuesTouched['tire_pressure']} name={'tire_pressure'} errors={errors} />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group controlId="tire_circumference">
                <Form.Label>Rehvi ümbermõõt "I"</Form.Label>
                <InputGroup>
                  <Form.Control type="number" value={protocolCreateData.tire_circumference} onChange={extendProtocolData} />
                  <InputGroup.Append>
                    <InputGroup.Text>mm</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <ValidationErrorComponent hide={!valuesTouched['tire_circumference']} name={'tire_circumference'} errors={errors} />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group controlId="vehicle_coefficient">
                <Form.Label>Sõiduki tunnustegur "W"</Form.Label>
                <InputGroup>
                  <Form.Control type="number" value={protocolCreateData.vehicle_coefficient} onChange={extendProtocolData} />
                  <InputGroup.Append>
                    <InputGroup.Text>imp/km</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <ValidationErrorComponent hide={!valuesTouched['vehicle_coefficient']} name={'vehicle_coefficient'} errors={errors} />
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div className="form-section">
          <h3>4. Sõidumeeriku kontroll</h3>
          <Row>
            <Col xs={12} sm={6}>
              <FormElement errors={errors} value={protocolCreateData.smart} label={'Arukas sõidumeerik'} validationKey={'smart'} type={'switch'} onChange={extendFactory<boolean>('smart')}/>
            </Col>
            <Col xs={12} sm={6}>
              <FormElement errors={errors} value={protocolCreateData.manipulation_device_found} label={'Manipuleerimisseade tuvastatud'} validationKey={'manipulation_device_found'} type={'switch'} onChange={extendFactory<boolean>('manipulation_device_found')}/>
            </Col>
          </Row>
          {
            protocolCreateData.smart ? (
              <>
                <Row>
                  <Col xs={12} sm={6}>
                    <FormElement errors={errors} value={protocolCreateData.smart_gnss_verified} label={'GNSS kontrollitud'} validationKey={'smart_gnss_verified'} type={'switch'} onChange={extendFactory<boolean>('smart_gnss_verified')}/>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} sm={6}>
                    <FormElement errors={errors} value={protocolCreateData.smart_dsrc_sn} label={'DSRC Seerianumber'} validationKey={'smart_dsrc_sn'} type={'text'} onChange={extendFactory<string>('smart_dsrc_sn')}/>
                  </Col>
                  <Col xs={12} sm={6}>
                    <FormElement errors={errors} value={protocolCreateData.smart_seal_nr} label={'SMART plommi seerianumber'} validationKey={'smart_seal_nr'} type={'text'} onChange={extendFactory<string>('smart_seal_nr')}/>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Label>SMART plommi asukoht</Form.Label>
                    <Select
                      className='mb-3'
                      options={SMART_SEAL_LOCATION}
                      onChange={(v) => {
                          setProtocolCreateData({
                            ...protocolCreateData,
                            //@ts-ignore
                            smart_seal_location: v.value
                          });
                      }}

                      defaultValue={SMART_SEAL_LOCATION[0]}
                    />
                    <ValidationErrorComponent hide={!valuesTouched['smart_seal_location']} name={'smart_seal_location'} errors={errors} />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Label>Veose Liik</Form.Label>
                      <Select
                        className='mb-3'
                        options={SMART_TYPES}
                        onChange={(v) => {
                            setProtocolCreateData({
                              ...protocolCreateData,
                              //@ts-ignore
                              smart_type: v.value
                            });
                        }}

                        defaultValue={SMART_TYPES[0]}
                      />
                    <ValidationErrorComponent hide={!valuesTouched['smart_type']} name={'smart_type'} errors={errors} />
                  </Col>
                </Row>
              </>
            ) : null
          }
          <Row>
            <Col xs={12} sm={6}>
              <Form.Group controlId="tachograph_constant">
                <Form.Label>Sõidumeeriku konstant "K"</Form.Label>

                <InputGroup>
                  <Form.Control type="number" value={protocolCreateData.tachograph_constant} onChange={extendProtocolData} />
                  <InputGroup.Append>
                    <InputGroup.Text>imp/km</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <ValidationErrorComponent hide={!valuesTouched['tachograph_constant']} name={'tachograph_constant'} errors={errors} />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group controlId="speed_deviation">
                <Form.Label>Kiiruse hälve</Form.Label>
                <InputGroup>
                  <Form.Control type="number" value={protocolCreateData.speed_deviation} onChange={extendProtocolData} />
                  <InputGroup.Append>
                    <InputGroup.Text>km/h</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <ValidationErrorComponent hide={!valuesTouched['speed_deviation']} name={'speed_deviation'} errors={errors} />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group controlId="distance_deviation">
                <Form.Label>Vahemaa hälve</Form.Label>
                <InputGroup>
                  <Form.Control type="number" value={protocolCreateData.distance_deviation} onChange={extendProtocolData} />
                  <InputGroup.Append>
                    <InputGroup.Text>m/1000m</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <ValidationErrorComponent hide={!valuesTouched['distance_deviation']} name={'distance_deviation'} errors={errors} />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group controlId="time_deviation">
                <Form.Label>Aja hälve</Form.Label>
                <InputGroup>
                  <Form.Control type="number" value={protocolCreateData.time_deviation} onChange={extendProtocolData} step="0.1"/>
                  <InputGroup.Append>
                    <InputGroup.Text>s/24h</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <ValidationErrorComponent hide={!valuesTouched['time_deviation']} name={'time_deviation'} errors={errors} />
              </Form.Group>
            </Col>
          </Row>
        </div>

        <div className="form-section">
          <h3>5. Kiiruspiiraja kontroll</h3>
          <Row>
            <Col xs={12} sm={6}>
              <Form.Group controlId="speed_limiter">
                <Form.Check
                  type="switch"
                  checked={protocolCreateData.speed_limiter}
                  label="Kiiruspiiraja"
                  onChange={extendProtocolBoolData}
                />
                <ValidationErrorComponent hide={!valuesTouched['speed_limiter']} name={'speed_limiter'} errors={errors} />
              </Form.Group>
            </Col>

            { protocolCreateData.speed_limiter ? (
              <Col xs={12} sm={6}>
                <Form.Group controlId="speed_limiter_top_speed">
                  <Form.Label>Max kiirus</Form.Label>
                  <InputGroup>
                    <Form.Control type="number" value={protocolCreateData.speed_limiter_top_speed} onChange={extendProtocolData} />
                    <InputGroup.Append>
                      <InputGroup.Text>km/h</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                  <ValidationErrorComponent hide={!valuesTouched['speed_limiter_top_speed']} name={'speed_limiter_top_speed'} errors={errors} />
                </Form.Group>
              </Col>
            ) : null }
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <Form.Group controlId="diagram_card_archived">
                <Form.Check
                  type="switch"
                  label="Diagrammketas joonistatud ning arhiveeritud"
                  checked={protocolCreateData.diagram_card_archived}
                  onChange={extendProtocolBoolData}
                />
                <ValidationErrorComponent hide={!valuesTouched['diagram_card_archived']} name={'diagram_card_archived'} errors={errors} />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group controlId="tachograph_archived">
                <Form.Check
                  type="switch"
                  checked={protocolCreateData.tachograph_archived}
                  label="Digimeeriku andmed arhiveeritud"
                  onChange={extendProtocolBoolData}
                />
                <ValidationErrorComponent hide={!valuesTouched['tachograph_archived']} name={'tachograph_archived'} errors={errors} />
              </Form.Group>
            </Col>
          </Row>
        </div>
        <Button type="submit" className={'btn-primary mt-4'} disabled={hasError} block >Salvesta</Button>
      </Form>
    </Container>
  );
}

export default Protocol;
