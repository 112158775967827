import React from 'react';
import { Pagination } from 'react-bootstrap';

interface Props {
  count: number;
  pageNumber: number;
  goToPage: (page: number) => void;
  limit: number;
}

function SmPagination ({count, pageNumber, goToPage, limit}: Props) {
  const lastPage = Math.ceil(count / limit);
  const pages = [-2, -1, 0, 1, 2]
    .map((p) => pageNumber + p)
    .filter((p) => !(p < 1 || p > lastPage));

  return (
    <Pagination className={'justify-content-center'}>
      <Pagination.First onClick={() => goToPage(1)} disabled={pageNumber === 1} />
      <Pagination.Prev onClick={() => goToPage(pageNumber - 1)} disabled={pageNumber === 1} />
      {pages.map((page, index) => (
        <Pagination.Item key={index} active={page === pageNumber} onClick={() => goToPage(page)}>{page}</Pagination.Item>
      ))}
      <Pagination.Next onClick={() => goToPage(pageNumber + 1)} disabled={pageNumber === lastPage} />
      <Pagination.Last onClick={() => goToPage(lastPage)} disabled={pageNumber === lastPage} />
    </Pagination>
  );
}

export default SmPagination;
