import * as Joi from '@hapi/joi';

export const CompanyUpdateValidationSchema = Joi.object({
  seal_nr: Joi.string().required().label('Plomminumber'),
  eak: Joi.string().required().label('EAK number'),
  name: Joi.string().min(0).required().label('Nimi'),
  country: Joi.string().min(0).required().label('Riik'),
  county: Joi.string().min(0).required().label('Maakond'),
  city: Joi.string().min(0).required().label('Linn'),
  address: Joi.string().min(0).required().label('Aadress'),
  phone: Joi.string().min(0).required().label('Telefon'),
});

export const CompanyUpdateProtocolCounterValidationSchema = Joi.object({
  protocol_counter: Joi.number().min(0).required().label('Protokolli loendur')
});

export const CompanyInviteSchema = Joi.object({
  email: Joi.string().min(0).required().label('Email'),
  company_name: Joi.string().min(0).required().label('Ettevõtte nimi'),
});
