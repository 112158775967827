import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import FormElement from '../../components/form/FormElement';
import { createValidator } from '../../lib/core';
import { inviteCompany } from '../../lib/services/company.service';
import { CompanyInviteSchema } from '../../common/validators/company';

const companyInviteValidator = createValidator(CompanyInviteSchema);

function AddCompany () {
  const history = useHistory();
  const [errors, setErrors] = useState<any>();
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function validate() {
      const {error} = await companyInviteValidator({ email, company_name: companyName });

      setErrors(error);
    }

    validate();
  }, [email, companyName]);

  async function handleFormSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    try {
      const {error} = await inviteCompany({email, company_name: companyName});
      setErrors(error);
      setIsLoading(false);

      if (!error) {
        history.push('/companies')
      }
    } catch (e) {
      setIsLoading(false);
    }
  }
  return (
    <>
      <h1>Lisa Ettevõte</h1>

      <Form onSubmit={handleFormSubmit}>
        <div className="form-section">
          <FormElement errors={errors} value={companyName} label={'Ettevõtte nimi'} validationKey={'company_name'} type={'text'} onChange={(v) => { setCompanyName(v);}}/>
          <FormElement errors={errors} value={email} label={'Email'} validationKey={'email'} type={'email'} onChange={(v) => { setEmail(v);}}/>

        </div>
        <Button type="submit" className={'btn-primary'} disabled={isLoading || !!errors} block >Saada kutse</Button>
      </Form>
    </>
  );
}

export default AddCompany;
