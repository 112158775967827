import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError } from '../core';
import { Dispatch } from 'react';
import { ContactTableFields, ListQuery, RouteContactListOutput } from '../../common/types';
import { listContactAction } from '../services/contact.service';

export interface ContactListState {
  list: ContactTableFields[],
  count: number;
  loading: boolean;
  error: any;
}

let initialState: ContactListState = {
  list: [],
  count: 0,
  loading: false,
  error: null
};

const slice = createSlice({
  name: 'contactList',
  initialState,
  reducers: {
    contactListPending (state) {
      state.loading = true;
    },
    contactListFulfilled(state, action: PayloadAction<RouteContactListOutput>) {
      state.loading = false;
      state.count = action.payload.count;
      state.list = action.payload.items;
      state.error = undefined;
    },
    contactListRejected(state, action: PayloadAction<ApiError>) {
      state.loading = false;
      state.error = action.payload;
      state.list = [];
      state.count = 0;
    }
  },
});

export function listContact(query: Partial<ListQuery>) {
  return (dispatch: Dispatch<any>) => {
    dispatch(slice.actions.contactListPending);
    const action = listContactAction({ query }, {
      onSuccess: (r) => dispatch(slice.actions.contactListFulfilled(r)),
      onFailure: (e) => dispatch(slice.actions.contactListRejected(e))
    });
    dispatch(action);
  }
}

export default slice;