import React from 'react';
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';
import et from 'date-fns/locale/et';
registerLocale('et', et);

function SmDatePicker (props: ReactDatePickerProps) {
    const {dateFormat, locale, ...otherProps} = props;
    return <DatePicker {...otherProps} locale={'et'} dateFormat={'MMMM d, yyyy'} />
}

export default SmDatePicker;