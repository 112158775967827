import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import FormElement from '../../components/form/FormElement';
import { createValidator } from '../../lib/core';
import { createContactRequest } from '../../lib/services/contact.service';

import { ContactCreateValidationSchema } from '../../common/validators/contact';

const contactAddValidator = createValidator(ContactCreateValidationSchema);

function AddContact () {
  const history = useHistory();
  const [errors, setErrors] = useState<any>();
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function validate() {
      const {error} = await contactAddValidator({ email, name: companyName, phone });

      setErrors(error);
    }

    validate();
  }, [email, companyName, phone]);

  async function handleFormSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    try {
      const {error} = await createContactRequest({email, name: companyName, phone});
      setErrors(error);
      setIsLoading(false);

      if (!error) {
        history.push('/contacts')
      }
    } catch (e) {
      setIsLoading(false);
    }
  }
  return (
    <>
      <h1>Lisa Kontakt</h1>

      <Form onSubmit={handleFormSubmit}>
        <div className="form-section">
          <FormElement errors={errors} value={companyName} label={'Ettevõtte nimi'} validationKey={'company_name'} type={'text'} onChange={(v) => { setCompanyName(v);}}/>
          <FormElement errors={errors} value={email} label={'Email'} validationKey={'email'} type={'email'} onChange={(v) => { setEmail(v);}}/>
          <FormElement errors={errors} value={phone} label={'Telefon'} validationKey={'phone'} type={'text'} onChange={(v) => { setPhone(v);}}/>

        </div>
        <Button type="submit" className={'btn-primary'} disabled={isLoading || !!errors} block >Lisa kontakt</Button>
      </Form>
    </>
  );
}

export default AddContact;
